import { createReducer } from '@reduxjs/toolkit'
import { AuthTokens, Chain } from 'types/dexbarn'

import { clearTokens, updateTokens } from './actions'

interface AuthenticationState {
  readonly tokens: {
    [chain in Chain]: {
      [address: string]: AuthTokens | undefined
    }
  }
}

const initialState: AuthenticationState = {
  tokens: {
    avalanche: {},
    monad: {},
    solana: {}
  }
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateTokens, (state, { payload }) => {
      const { address, chain, tokens } = payload
      if (!state.tokens[chain]) {
        state.tokens[chain] = {}
      }
      state.tokens[chain][address] = tokens
    })
    .addCase(clearTokens, (state, { payload }) => {
      const { address, chain } = payload
      if (state.tokens[chain]) {
        delete state.tokens[chain][address]
      }
    })
)
