import { Flex, Image, Text, useColorMode, VStack } from '@chakra-ui/react'
import BannerDark from 'assets/token_mill_banner_dark.png'
import BannerLight from 'assets/token_mill_banner_light.png'
import HeaderLink from 'components/Header/HeaderLink'
import React from 'react'

const HomeHeader = () => {
  const { colorMode } = useColorMode()

  return (
    <VStack mb={8} spacing={2}>
      <Image
        src={colorMode === 'light' ? BannerLight : BannerDark}
        w="full"
        h={{ base: '150px', md: 'auto' }}
        objectFit="cover"
      />
      <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight="semibold" my={2}>
        Where MILLionaires are made
      </Text>
      <Flex
        gap={{ base: 2, md: 4 }}
        align="flex-start"
        flexDir={{ base: 'column', md: 'row' }}
        w={{ base: 'full', md: 'auto' }}
        px={{ base: 4, md: 0 }}
      >
        <HeaderLink
          title="[Launch Token]"
          to="/new"
          bg="accent.500"
          w={{ base: 'full', md: 'auto' }}
          textAlign="center"
        />

        <VStack align="center" w={{ base: 'full', md: 'auto' }}>
          <HeaderLink
            _dark={{ bg: 'bgSecondary' }}
            title="[Refer your friends]"
            to="/referrals"
            w={{ base: 'full', md: 'auto' }}
            textAlign="center"
          />
          <Text fontSize="sm" textColor="textSecondary">
            You get up to 50% of fees
          </Text>
        </VStack>
      </Flex>
    </VStack>
  )
}

export default HomeHeader
