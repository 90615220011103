import {
  Button,
  Flex,
  FlexProps,
  Hide,
  Spacer,
  useDisclosure
} from '@chakra-ui/react'
import ConnectButton from 'components/ConnectButton'
import HeaderSearchPanel from 'components/HeaderSearchPanel'
import HowItWorksModal from 'components/HowItWorksModal'
import TransactionSettingsPopover from 'components/TransactionSettings/Popover'
import UKDisclaimerBanner from 'components/UKDisclaimerBanner'
import { IS_MONAD_ENABLED } from 'constants/chains'
import React from 'react'
import { useOriginCountry } from 'state/settings/hooks'

import HeaderLink from './HeaderLink'

const HeaderContent = (): JSX.Element => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Flex
      alignItems="center"
      w="full"
      maxW="1600px"
      margin="0 auto"
      p={4}
      gap={4}
    >
      <Flex gap={4} flexShrink={0} align="center">
        <HeaderLink title="[Home]" to="/" />
        <HeaderLink title="[Create]" to="/new" bg="accent.500" />
        <>
          <Button
            variant="boxShadowFlat"
            onClick={onOpen}
            fontSize="md"
            fontWeight="normal"
          >
            [How it works?]
          </Button>
          <HowItWorksModal isOpen={isOpen} onClose={onClose} />
        </>
      </Flex>

      <Hide below="lg">
        <HeaderSearchPanel />
      </Hide>

      <Spacer />

      <Hide below="lg">
        {!IS_MONAD_ENABLED && <TransactionSettingsPopover />}

        <Flex columnGap={4} flexShrink={0}>
          <ConnectButton />
        </Flex>
      </Hide>
    </Flex>
  )
}

const HeaderContainer = (props: FlexProps) => {
  return (
    <Flex
      zIndex={11}
      position="sticky"
      width="full"
      top={0}
      minH="64px"
      flexDir="column"
      bg="bgSecondary"
      padding={{
        base: '1rem 0.25rem 1rem 1rem',
        md: '1rem 2rem',
        xs: '1rem'
      }}
      {...props}
    />
  )
}

const Header = () => {
  const { originCountryCode } = useOriginCountry()

  if (originCountryCode === 'GB') {
    return (
      <HeaderContainer>
        <UKDisclaimerBanner />

        <Hide below="md">
          <HeaderContent />
        </Hide>
      </HeaderContainer>
    )
  }

  return (
    <Hide below="md">
      <HeaderContainer>
        <HeaderContent />
      </HeaderContainer>
    </Hide>
  )
}

export default Header
