import { Token } from 'types/token'
import { zeroAddress } from 'viem'

import { TokenMillChainId } from './chains'

export const CNATIVE: { [chainId in TokenMillChainId]: Token } = {
  [TokenMillChainId.FUJI]: {
    address: zeroAddress,
    decimals: 18,
    name: 'AVAX',
    symbol: 'AVAX'
  },
  [TokenMillChainId.AVALANCHE]: {
    address: zeroAddress,
    decimals: 18,
    name: 'AVAX',
    symbol: 'AVAX'
  },
  [TokenMillChainId.SOLANA_DEVNET]: {
    address: zeroAddress,
    decimals: 9,
    name: 'SOL',
    symbol: 'SOL'
  },
  [TokenMillChainId.SOLANA]: {
    address: zeroAddress,
    decimals: 9,
    name: 'SOL',
    symbol: 'SOL'
  },
  [TokenMillChainId.MONAD_TESTNET]: {
    address: zeroAddress,
    decimals: 18,
    name: 'MON',
    symbol: 'MON'
  }
}

export const WNATIVE: { [chainId in TokenMillChainId]: Token } = {
  [TokenMillChainId.FUJI]: {
    address: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
    decimals: 18,
    name: 'Wrapped AVAX',
    symbol: 'WAVAX'
  },
  [TokenMillChainId.AVALANCHE]: {
    address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    decimals: 18,
    name: 'Wrapped AVAX',
    symbol: 'WAVAX'
  },
  [TokenMillChainId.SOLANA_DEVNET]: {
    address: 'So11111111111111111111111111111111111111112',
    decimals: 9,
    name: 'Wrapped SOL',
    symbol: 'wSOL'
  },
  [TokenMillChainId.SOLANA]: {
    address: 'So11111111111111111111111111111111111111112',
    decimals: 9,
    name: 'Wrapped SOL',
    symbol: 'wSOL'
  },
  [TokenMillChainId.MONAD_TESTNET]: {
    address: '0x760AfE86e5de5fa0Ee542fc7B7B713e1c5425701',
    decimals: 18,
    name: 'Wrapped MONAD',
    symbol: 'wMON'
  }
}
