import useCreateMarketAndTokenEVM from 'hooks/evm/useCreateMarketAndToken'
import useCreateTokenMarketSolana from 'solana/hooks/useCreateMarketAndToken'
import { Chain } from 'types/dexbarn'

export type VestingType = '1hour' | '1day' | '3days' | '1week'

export interface VestingArgs {
  baseAmount: number
  quoteAmount: number
  vestingType: VestingType
}

export interface CreateMarketAndTokenArgs {
  askPrices: number[]
  bidPrices: number[]
  chain: Chain
  creatorFeeShareBps: number
  decimals: number
  name: string
  stakingFeeShareBps: number
  symbol: string
  totalSupply: number
  enabled?: boolean
  vestingArgs?: VestingArgs
}

const useCreateMarketAndToken = (props: CreateMarketAndTokenArgs) => {
  const { chain } = props
  const isEVM = chain === 'avalanche' || chain === 'monad'

  const createEVM = useCreateMarketAndTokenEVM({
    ...props,
    enabled: props.enabled && isEVM
  })

  const createSolana = useCreateTokenMarketSolana(props)

  switch (chain) {
    case 'avalanche':
    case 'monad':
      return createEVM
    case 'solana':
      return {
        createMarketAndTokenAsync: createSolana.mutateAsync,
        error:
          createSolana.error instanceof Error &&
          (createSolana.error as Error).message.includes(
            'User rejected the request'
          )
            ? undefined
            : createSolana.error,
        isLoading: createSolana.isPending
      }
  }
}

export default useCreateMarketAndToken
