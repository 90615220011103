import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { IS_MONAD_ENABLED } from 'constants/chains'
import React from 'react'
import { ChainFilter } from 'types/dexbarn'

interface ChainMenuProps {
  currentChain: ChainFilter
  onChange: (chain: ChainFilter) => void
}

const ChainMenu = ({ currentChain, onChange }: ChainMenuProps) => {
  const chains: Array<ChainFilter> = IS_MONAD_ENABLED
    ? ['monad']
    : ['all', 'avalanche', 'solana']
  const chainLabels: Record<ChainFilter, string> = {
    all: 'All Networks',
    avalanche: 'Avalanche',
    monad: 'Monad',
    solana: 'Solana'
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="boxShadowFlat"
        fontSize="sm"
        w={{ base: 'full', md: 'auto' }}
        rightIcon={<ChevronDownIcon />}
        _dark={{ bg: 'bgSecondary' }}
      >
        {chainLabels[currentChain]}
      </MenuButton>
      <MenuList>
        {chains.map((chain) => (
          <MenuItem key={chain} onClick={() => onChange(chain)}>
            {chainLabels[chain]}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default ChainMenu
