import * as spl from '@solana/spl-token'
import { PublicKey } from '@solana/web3.js'
import { useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { Chain } from 'types/dexbarn'
import { TopHolder } from 'types/market'

interface DexbarnTopHolder {
  amountStaked: number
  amountVested: number
  chain: Chain
  currentBalance: number
  tokenMillMarketId: string
  userId: string
}

interface UseGetTopHoldersProps {
  baseTokenAddress: string
  chain: Chain
  marketAddress: string
}

const useGetTopHolders = ({
  baseTokenAddress,
  chain,
  marketAddress
}: UseGetTopHoldersProps) => {
  const fetchTopHolders = useSolbarnGet<DexbarnTopHolder[]>(
    `/v2/markets/${chain}/${marketAddress}/top-holders`
  )

  return useQuery<DexbarnTopHolder[], Error, TopHolder[]>({
    enabled: !!chain && !!marketAddress,
    queryFn: fetchTopHolders,
    queryKey: ['topHolders', chain, marketAddress],
    select: (data) => {
      return data.map((holder) => {
        let isMarket = false

        if (chain === 'solana') {
          const marketBaseToken2022ATA = spl.getAssociatedTokenAddressSync(
            new PublicKey(baseTokenAddress),
            new PublicKey(marketAddress),
            true,
            spl.TOKEN_2022_PROGRAM_ID
          )

          const marketBaseTokenATA = spl.getAssociatedTokenAddressSync(
            new PublicKey(baseTokenAddress),
            new PublicKey(marketAddress),
            true,
            spl.TOKEN_PROGRAM_ID
          )

          isMarket =
            holder.userId.toLowerCase() ===
              marketBaseTokenATA.toBase58().toLowerCase() ||
            holder.userId.toLowerCase() ===
              marketBaseToken2022ATA.toBase58().toLowerCase()
        } else {
          isMarket = holder.userId.toLowerCase() === marketAddress.toLowerCase()
        }

        return {
          balance: holder.currentBalance,
          isMarket,
          staked: holder.amountStaked,
          total:
            holder.currentBalance + holder.amountStaked + holder.amountVested,
          userAddress: holder.userId,
          vesting: holder.amountVested
        }
      })
    }
  })
}

export default useGetTopHolders
