import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  Center,
  HStack,
  LinkBox,
  LinkOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import useGetMarketRecentActivity from 'hooks/barn/useGetMarketRecentActivity'
import React, { useEffect, useMemo, useState } from 'react'
import { Chain } from 'types/dexbarn'
import { RecentTrade } from 'types/market'
import { shortenAddress } from 'utils/addresses'
import { getBlockExplorer } from 'utils/chains'
import { formatShortDistanceToNow, formattedNum } from 'utils/format'

const TimeAgo = ({ date }: { date: Date }) => {
  const [, setTick] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => setTick((t) => t + 1), 1000)
    return () => clearInterval(timer)
  }, [])

  return <Text>{formatShortDistanceToNow(date)} ago</Text>
}

interface TokenMillRecentTradesProps {
  chain: Chain
  liveTrades: RecentTrade[]
  marketAddress: string
  marketCreator: string
  setIsHoveringRow: React.Dispatch<React.SetStateAction<boolean>>
}

const TokenMillRecentTrades = ({
  chain,
  liveTrades,
  marketAddress,
  marketCreator,
  setIsHoveringRow
}: TokenMillRecentTradesProps) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useGetMarketRecentActivity({
      address: marketAddress,
      chain,
      pageSize: 10
    })

  const trades = useMemo(() => {
    const recentActivities = data?.pages.flatMap((page) => page) || []
    return [
      ...liveTrades,
      ...recentActivities.map((activity) => ({
        ...activity,
        baseTokenPriceUsd:
          activity.orderType === 'Sell'
            ? activity.tokenIn.priceUsd
            : activity.tokenOut.priceUsd
      }))
    ]
  }, [liveTrades, data?.pages])

  return (
    <Box w={{ base: '100vw', md: 'full' }} pt={2} px={{ base: 0, md: 2 }}>
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th py={4}>Date</Th>
                <Th py={4}>Type</Th>
                <Th py={4}>Maker</Th>
                <Th py={4} isNumeric>
                  USD
                </Th>
                <Th py={4} isNumeric>
                  Amount In
                </Th>
                <Th py={4} isNumeric>
                  Amount Out
                </Th>
                <Th py={4} isNumeric>
                  Price
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {trades.map((activity, index) => {
                const totalTradeUsd =
                  Number(activity.amountIn) * activity.tokenIn.priceUsd

                const isTokenCreator =
                  activity.userAddress.toLowerCase() ===
                  marketCreator.toLowerCase()

                return (
                  <LinkBox
                    as={Tr}
                    key={index}
                    cursor="pointer"
                    transform="scale(1)"
                    role="group"
                    _hover={{ bg: 'hover' }}
                    onMouseEnter={() => setIsHoveringRow(true)}
                    onMouseLeave={() => setIsHoveringRow(false)}
                  >
                    <Td>
                      <LinkOverlay
                        isExternal
                        aria-label="link to transaction on explorer"
                        href={
                          getBlockExplorer(activity.txnHash, chain, 'tx').url
                        }
                      />
                      <TimeAgo date={new Date(activity.timestamp)} />
                    </Td>
                    <Td>
                      <Text
                        textColor={
                          activity.orderType === 'Sell'
                            ? 'candleRed'
                            : 'candleGreen'
                        }
                      >
                        {activity.orderType === 'Sell' ? 'SELL' : 'BUY'}
                      </Text>
                    </Td>
                    <Td>
                      <HStack>
                        <Text>{shortenAddress(activity.userAddress, 2)}</Text>
                        {isTokenCreator && (
                          <Badge size="sm" colorScheme="yellow">
                            DEV
                          </Badge>
                        )}
                      </HStack>
                    </Td>
                    <Td isNumeric>
                      {formattedNum(totalTradeUsd, { places: 5, usd: true })}
                    </Td>
                    <Td isNumeric>
                      {formattedNum(activity.amountIn, { places: 5 })}{' '}
                      {activity.tokenIn.symbol}
                    </Td>
                    <Td isNumeric>
                      {formattedNum(activity.amountOut, { places: 5 })}{' '}
                      {activity.tokenOut.symbol}
                    </Td>
                    <Td isNumeric>
                      {formattedNum(activity.baseTokenPriceUsd, {
                        allowSmallDecimals: true,
                        usd: true
                      })}
                    </Td>

                    <Td>
                      <ExternalLinkIcon mx="2px" />
                    </Td>
                  </LinkBox>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {hasNextPage ? (
        <Center w="full" mt={4}>
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            color="textPrimary"
            isLoading={isFetchingNextPage}
            onClick={() => fetchNextPage()}
          >
            Load More
          </Button>
        </Center>
      ) : null}
    </Box>
  )
}

export default TokenMillRecentTrades
