import { useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { MarketData } from 'types/dexbarn'
import { useDebounce } from 'use-debounce'

interface UseSearchMarketsProps {
  query: string
}

const useSearchMarkets = ({ query }: UseSearchMarketsProps) => {
  const [debouncedQuery] = useDebounce(query, 300)

  const fetchMarkets = useSolbarnGet<MarketData[]>(
    `/v2/markets/search?chains=all&query=${debouncedQuery}&excludeLowVolumeMarkets=false&orderBy=trending&filterBy=d1`
  )

  return useQuery({
    enabled: debouncedQuery.length > 2,
    queryFn: () => fetchMarkets(),
    queryKey: ['SearchMarkets', debouncedQuery]
  })
}

export default useSearchMarkets
