import {
  Box,
  Center,
  Divider,
  Grid,
  Hide,
  Show,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { PublicKey } from '@solana/web3.js'
import TradingViewChart from 'components/TradingViewChart'
import { DEFAULT_EVM_CHAIN } from 'constants/chains'
import useGetMarketOf from 'hooks/evm/useGetMarketOf'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import useGetMarketStakingData from 'hooks/tokenmill/useGetMarketStakingData'
import useTokenBalance from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Chain } from 'types/dexbarn'
import { isSolanaAddress } from 'utils/addresses'
import { getChainId } from 'utils/chains'
import { deriveMarketAddressFromBaseToken } from 'utils/token'
import { isAddress } from 'viem'

import BondingCurvesChart from '../CreateToken/BondingCurvesChart'
import CreatorFees from './CreatorFees'
import TokenInfoSection from './TokenInfoSection'
import TokenMillDetailAnalytics from './TokenMillDetailAnalytics'
import TokenMillDetailDataTabs from './TokenMillDetailDataTabs'
import TokenMillDetailHeader from './TokenMillDetailHeader'
import TokenMillTradePanel from './TokenMillTradePanel'

const ChartContent = ({
  chain,
  isLoading,
  market,
  marketAddress
}: {
  chain: Chain
  isLoading: boolean
  market: ReturnType<typeof useGetMarket>['data']
  marketAddress: string
}) => {
  const { data: marketStakingData } = useGetMarketStakingData({
    baseTokenAddress: market?.baseToken.address,
    chain,
    marketAddress
  })

  if (isLoading) {
    return (
      <Center minH="300px">
        <Spinner />
      </Center>
    )
  }

  if (!market) return null

  const lockedSupply = marketStakingData?.totalLockedAmount
    ? Number(marketStakingData.totalLockedAmount.formatted)
    : 0

  return (
    <Tabs isLazy variant="bracketed" w="full" p={{ base: 4, md: 6 }}>
      <TabList>
        <Tab>Trading Chart</Tab>
        <Tab>Price Curve</Tab>
      </TabList>
      <TabPanels pt={4}>
        <TabPanel p={0}>
          <TradingViewChart
            chain={chain}
            tmMarketAddress={market.marketAddress}
            inputCurrency={market.baseToken}
            outputCurrency={market.quoteCurrency}
            defaultResolution={'5'}
            supportedResolutions={['5', '15', '60', '240', '720', '1D', '1W']}
          />
        </TabPanel>
        <TabPanel p={0}>
          <BondingCurvesChart
            chain={chain}
            circulatingSupply={market.circulatingSupply + lockedSupply}
            askPricePoints={market.askPricesPoints}
            bidPricePoints={market.bidPricesPoints}
            askPrice={market.askPrice}
            bidPrice={market.bidPrice}
            adjustVisibleRangeOnLoad={true}
            containerProps={{
              h: '50svh',
              maxH: { base: '400px', md: '500px' }
            }}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

interface TokenMillDetailProps {
  chain: Chain
  marketAddress: string
}

const TokenMillDetail = ({ chain, marketAddress }: TokenMillDetailProps) => {
  const {
    data: market,
    isLoading,
    refetch: refetchMarket
  } = useGetMarket({ chain, filterBy: 'd1', marketAddress })

  const {
    data: baseTokenBalance,
    isLoading: isLoadingBaseTokenBalance,
    refetch: refetchBaseTokenBalance
  } = useTokenBalance({
    chain,
    token: market?.baseToken.address
  })
  const debouncedRefetchBaseTokenBalance = debounce(
    refetchBaseTokenBalance,
    1000
  )

  const account = useAccountOnChain(chain)
  const isCreator =
    market?.creator.toLowerCase() === account.address?.toLowerCase()

  const pendingCreatorFees = market?.fees?.pendingCreatorFees

  return (
    <Box maxW="1600px" margin="0 auto">
      <Grid
        templateColumns={{ base: '1fr', lg: 'auto 1px 500px' }}
        alignItems="flex-start"
      >
        <VStack spacing={0}>
          <TokenMillDetailHeader
            marketAddress={marketAddress}
            chain={chain}
            market={market}
            isCreator={isCreator}
          />

          <Divider />

          <Show below="md">
            <VStack spacing={0} w="full">
              {market ? (
                <TokenMillTradePanel
                  chain={chain}
                  baseToken={market.baseToken}
                  baseTokenBalance={baseTokenBalance}
                  onBaseTokenBalanceChange={debouncedRefetchBaseTokenBalance}
                  quoteCurrency={market.quoteCurrency}
                  baseTokenLogoUrl={market.baseTokenLogoUrl}
                  marketAddress={marketAddress}
                  isLoadingBaseTokenBalance={isLoadingBaseTokenBalance}
                  askPrice={market.askPrice}
                  bidPrice={market.bidPrice}
                />
              ) : null}

              {isCreator &&
              pendingCreatorFees &&
              pendingCreatorFees > BigInt(0) ? (
                <>
                  <Divider />
                  <CreatorFees
                    marketAddress={marketAddress}
                    chain={chain}
                    quoteCurrency={market.quoteCurrency}
                    pendingCreatorFees={pendingCreatorFees}
                    onClaimSuccess={refetchMarket}
                  />
                </>
              ) : null}

              <Divider />

              <Show above="md">
                <TokenMillDetailAnalytics
                  marketAddress={marketAddress}
                  chain={chain}
                />
              </Show>
            </VStack>
            <Divider />
          </Show>

          <Show below="md">
            <Divider />
            <ChartContent
              isLoading={isLoading}
              market={market}
              chain={chain}
              marketAddress={marketAddress}
            />
            <Divider />
            {market && (
              <TokenMillDetailAnalytics
                marketAddress={marketAddress}
                chain={chain}
              />
            )}
          </Show>

          <Hide below="md">
            <ChartContent
              isLoading={isLoading}
              market={market}
              chain={chain}
              marketAddress={marketAddress}
            />
          </Hide>

          <Divider />

          <TokenMillDetailDataTabs
            marketAddress={marketAddress}
            chain={chain}
            market={market}
          />

          <Show below="md">
            <Divider />
          </Show>
        </VStack>

        <Hide below="md">
          <Divider orientation="vertical" h="full" />
        </Hide>

        <Hide below="md">
          <VStack spacing={0} w="full">
            <TokenInfoSection
              chain={chain}
              market={market}
              isCreator={isCreator}
            />

            <Divider />

            {market ? (
              <TokenMillTradePanel
                chain={chain}
                baseToken={market.baseToken}
                baseTokenBalance={baseTokenBalance}
                onBaseTokenBalanceChange={debouncedRefetchBaseTokenBalance}
                quoteCurrency={market.quoteCurrency}
                baseTokenLogoUrl={market.baseTokenLogoUrl}
                marketAddress={marketAddress}
                isLoadingBaseTokenBalance={isLoadingBaseTokenBalance}
                askPrice={market.askPrice}
                bidPrice={market.bidPrice}
              />
            ) : null}

            {isCreator &&
            pendingCreatorFees &&
            pendingCreatorFees > BigInt(0) ? (
              <>
                <Divider />
                <CreatorFees
                  marketAddress={marketAddress}
                  chain={chain}
                  quoteCurrency={market.quoteCurrency}
                  pendingCreatorFees={pendingCreatorFees}
                  onClaimSuccess={refetchMarket}
                />
              </>
            ) : null}

            <Divider />

            <TokenMillDetailAnalytics
              marketAddress={marketAddress}
              chain={chain}
            />
          </VStack>
        </Hide>
      </Grid>
    </Box>
  )
}

export const TokenMillDetailWithTokenParam = () => {
  const { chain: chainParam, tokenAddress } = useParams()

  const chain = chainParam as Chain
  const isValidChain =
    chainParam === 'avalanche' ||
    chainParam === 'solana' ||
    chainParam === 'monad'
  const chainId = getChainId(chain)

  const { data: marketAddressEVM, isLoading: isLoadingMarketAddressEVM } =
    useGetMarketOf({
      chainId,
      enabled: isValidChain && chain === DEFAULT_EVM_CHAIN,
      tokenAddress
    })

  if (!tokenAddress || !isValidChain) {
    return <Navigate to="/404" />
  }

  switch (chain) {
    case 'avalanche':
    case 'monad':
      if (isLoadingMarketAddressEVM) {
        return (
          <Center minH="70vh">
            <Spinner />
          </Center>
        )
      }

      if (!marketAddressEVM) {
        return <Navigate to="/404" />
      }

      return <TokenMillDetail marketAddress={marketAddressEVM} chain={chain} />
    case 'solana':
      if (!isSolanaAddress(tokenAddress)) {
        return <Navigate to="/404" />
      }

      const marketAddress = deriveMarketAddressFromBaseToken(
        new PublicKey(tokenAddress)
      )

      if (!marketAddress) {
        return <Navigate to="/404" />
      }

      return (
        <TokenMillDetail
          marketAddress={marketAddress.toBase58()}
          chain={chain}
        />
      )
  }
}

const TokenMillDetailWithRedirect = () => {
  const { chain: chainParam, marketAddress } = useParams()

  if (
    !marketAddress ||
    (chainParam !== 'avalanche' &&
      chainParam !== 'solana' &&
      chainParam !== 'monad')
  ) {
    return <Navigate to="/404" />
  }

  const chain = chainParam as Chain

  switch (chain) {
    case 'avalanche':
    case 'monad':
      if (!isAddress(marketAddress)) {
        return <Navigate to="/404" />
      }
      break
    case 'solana':
      if (!isSolanaAddress(marketAddress)) {
        return <Navigate to="/404" />
      }
      break
  }

  return <TokenMillDetail marketAddress={marketAddress} chain={chain} />
}

export default TokenMillDetailWithRedirect
