import { createReducer } from '@reduxjs/toolkit'

import {
  setAreTermsAccepted,
  setFeeMode,
  setIsHeaderCollapsed,
  setIsUKDisclaimerHidden,
  setMaxTransactionFee,
  setOriginCountryCode
} from './actions'

export const DEFAULT_MAX_TRANSACTION_FEE = '0.005'
export const DEFAULT_FEE_MODE = 'maxCap'

export type FeeMode = 'maxCap' | 'exactFee'

interface SettingsState {
  readonly areTermsAccepted?: boolean
  readonly feeMode?: FeeMode
  readonly isHeaderCollapsed?: boolean
  readonly isUKDisclaimerHidden?: boolean
  readonly maxTransactionFee?: string
  readonly originCountryCode?: string
}

const initialState: SettingsState = {
  areTermsAccepted: false,
  feeMode: DEFAULT_FEE_MODE,
  isHeaderCollapsed: false,
  maxTransactionFee: DEFAULT_MAX_TRANSACTION_FEE
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setAreTermsAccepted, (state, action) => {
      state.areTermsAccepted = action.payload
    })

    .addCase(setOriginCountryCode, (state, action) => {
      state.originCountryCode = action.payload
    })
    .addCase(setIsUKDisclaimerHidden, (state, action) => {
      state.isUKDisclaimerHidden = action.payload
    })
    .addCase(setMaxTransactionFee, (state, action) => {
      state.maxTransactionFee = action.payload
    })
    .addCase(setFeeMode, (state, action) => {
      state.feeMode = action.payload
    })
    .addCase(setIsHeaderCollapsed, (state, action) => {
      state.isHeaderCollapsed = action.payload
    })
)
