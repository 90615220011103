import { useQuery } from '@tanstack/react-query'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { Chain } from 'types/dexbarn'

const useGetUserReferralCodes = () => {
  const { address: addressEVM } = useAccountOnChain('avalanche')
  const { address: addressSolana } = useAccountOnChain('solana')

  const fetchReferrerCodeEVM = useSolbarnGet<string>(
    `/v2/users/${addressEVM}/referral-code`
  )

  const fetchReferrerCodeSolana = useSolbarnGet<string>(
    `/v2/users/${addressSolana}/referral-code`
  )

  return useQuery({
    enabled: !!addressEVM,
    queryFn: async () => {
      const chainReferralCodes: { [chain in Chain]: string } = {
        avalanche: '',
        monad: '',
        solana: ''
      }

      if (!!addressEVM) {
        const refCodeEVM = await fetchReferrerCodeEVM().catch(() => '')
        chainReferralCodes.avalanche = refCodeEVM
        chainReferralCodes.monad = refCodeEVM
      }

      if (!!addressSolana) {
        chainReferralCodes.solana = await fetchReferrerCodeSolana().catch(
          () => ''
        )
      }

      return chainReferralCodes
    },
    queryKey: ['GetReferrerCode', addressEVM, addressSolana]
  })
}

export default useGetUserReferralCodes
