import { Image, ImageProps } from '@chakra-ui/react'
import AvaxLogo from 'assets/avalanche.svg'
import MonadLogo from 'assets/monad.jpg'
import SolanaLogo from 'assets/solana.jpg'
import React from 'react'
import { Chain } from 'types/dexbarn'

interface ChainIconProps {
  chain: Chain
  size?: string | number
}

const ChainIcon: React.FC<ChainIconProps & Omit<ImageProps, 'src'>> = ({
  chain,
  size = '24px',
  ...props
}) => {
  const getChainLogo = (chain: Chain) => {
    switch (chain) {
      case 'avalanche':
        return AvaxLogo
      case 'solana':
        return SolanaLogo
      case 'monad':
        return MonadLogo
    }
  }

  const logo = getChainLogo(chain)

  if (!logo) {
    return null
  }

  return (
    <Image
      src={logo}
      alt={`${chain} logo`}
      width={size}
      height={size}
      borderRadius="full"
      {...props}
    />
  )
}

export default ChainIcon
