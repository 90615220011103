import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  HStack,
  Link,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import React, { useState } from 'react'
import { Chain } from 'types/dexbarn'
import { SolbarnMarketQueryParam } from 'types/solbarn'
import { shortenAddress } from 'utils/addresses'
import { getBlockExplorer } from 'utils/chains'
import { formatShortDistanceToNow, formattedNum } from 'utils/format'

interface AnalyticsRowProps {
  title: string
  link?: string
  secondaryValue?: string
  tooltipLabel?: string
  value?: string | React.ReactNode
}

interface TimeTabProps {
  label: string
  pctChange?: number
}

const TimeTab = ({ label, pctChange }: TimeTabProps) => (
  <Tab justifyContent="flex-start" justifyItems="flex-start">
    <VStack spacing={0}>
      <Text>{label}</Text>
      {pctChange !== undefined && (
        <Text color={pctChange >= 0 ? 'green.400' : 'red.500'}>
          {pctChange >= 0 ? '+' : ''}
          {formattedNum(pctChange, { places: 2 })}%
        </Text>
      )}
    </VStack>
  </Tab>
)

const AnalyticsRow = ({
  link,
  secondaryValue,
  title,
  tooltipLabel,
  value
}: AnalyticsRowProps) => {
  const Value = () => (
    <HStack>
      {typeof value === 'string' ? <Text>{value}</Text> : value}
      {secondaryValue ? (
        <Text textColor="textSecondary">{secondaryValue}</Text>
      ) : null}
    </HStack>
  )

  return (
    <HStack
      w="full"
      justify="space-between"
      fontSize="sm"
      borderBottom="1px solid"
      borderBottomColor="border"
      borderBottomStyle="dashed"
    >
      <Text
        textColor="textSecondary"
        textDecor={tooltipLabel ? 'underline' : 'none'}
        textDecorationStyle="dotted"
      >
        {tooltipLabel ? (
          <TouchFriendlyTooltip label={tooltipLabel}>
            {title}
          </TouchFriendlyTooltip>
        ) : (
          title
        )}
      </Text>

      {link ? (
        <Link isExternal href={link}>
          <HStack>
            <Value />
            <ExternalLinkIcon />
          </HStack>
        </Link>
      ) : (
        <Value />
      )}
    </HStack>
  )
}

interface TokenMillDetailAnalyticsProps {
  chain: Chain
  marketAddress: string
}

const TokenMillDetailAnalytics = ({
  chain,
  marketAddress
}: TokenMillDetailAnalyticsProps) => {
  const [filterBy, setFilterBy] =
    useState<SolbarnMarketQueryParam.FilterBy>('d1')
  const { data: market } = useGetMarket({
    chain,
    filterBy,
    marketAddress
  })

  const maxPriceUsd =
    market && market.quoteTokenPriceUsd
      ? market.askPricesPoints[market.askPricesPoints.length - 1].price *
        market.quoteTokenPriceUsd
      : undefined

  return (
    <VStack p={{ base: 4, md: 6 }} align="flex-start" w="full">
      <Tabs
        variant="bracketedSecondary"
        size="sm"
        mb={2}
        isFitted
        index={
          filterBy === 'm5'
            ? 0
            : filterBy === 'h1'
              ? 1
              : filterBy === 'h6'
                ? 2
                : 3
        }
        onChange={(index) =>
          setFilterBy(
            index === 0 ? 'm5' : index === 1 ? 'h1' : index === 2 ? 'h6' : 'd1'
          )
        }
        overflow={{ base: 'auto', md: 'visible' }}
        w={{ base: 'calc(100vw - 32px)', md: 'full' }}
      >
        <TabList>
          <TimeTab label="5m" pctChange={market?.baseTokenPctChange5m} />
          <TimeTab label="1h" pctChange={market?.baseTokenPctChange1h} />
          <TimeTab label="6h" pctChange={market?.baseTokenPctChange6h} />
          <TimeTab label="24h" pctChange={market?.baseTokenPctChange1d} />
        </TabList>
      </Tabs>

      <AnalyticsRow
        title="Current Price"
        secondaryValue={
          market?.baseTokenPriceUsd !== undefined
            ? `~${formattedNum(market.baseTokenPriceUsd, {
                allowSmallDecimals: true,
                usd: true
              })}`
            : undefined
        }
        value={
          market?.baseTokenPriceNative !== undefined
            ? `${formattedNum(market.baseTokenPriceNative, {
                allowSmallDecimals: true,
                places: 8
              })} ${market.quoteCurrency.symbol}`
            : undefined
        }
      />

      <AnalyticsRow
        title="Age"
        value={
          market?.createdAt
            ? formatShortDistanceToNow(new Date(market.createdAt))
            : undefined
        }
      />

      <AnalyticsRow
        title="Volume"
        value={
          market?.volumeUsd !== undefined
            ? formattedNum(market.volumeUsd, { usd: true })
            : undefined
        }
      />

      <AnalyticsRow
        title="Market Cap"
        value={
          market?.marketCapUsd !== undefined
            ? formattedNum(market.marketCapUsd, { usd: true })
            : undefined
        }
      />

      <AnalyticsRow
        title="FDV"
        value={
          market?.marketCapUsd !== undefined && market.baseTokenPriceUsd
            ? formattedNum(market.totalSupply * market.baseTokenPriceUsd, {
                usd: true
              })
            : undefined
        }
      />

      <AnalyticsRow
        title="Max FDV"
        value={
          market && maxPriceUsd
            ? formattedNum(market.totalSupply * maxPriceUsd, {
                usd: true
              })
            : undefined
        }
        tooltipLabel="Max FDV = Total Supply * Max Price (The FDV when all tokens have been bought from the bonding curve)."
      />

      <AnalyticsRow
        title="Liquidity"
        value={
          market?.liquidityUsd !== undefined
            ? formattedNum(market.liquidityUsd, { usd: true })
            : undefined
        }
      />

      <AnalyticsRow
        title="Transactions"
        value={
          market?.nbSwaps !== undefined
            ? formattedNum(market.nbSwaps)
            : undefined
        }
      />

      <AnalyticsRow
        title="Buys / Sells"
        value={
          <HStack spacing={1}>
            <Text color="green.500">{market?.nbBuys}</Text>
            <Text textColor="textSecondary">/</Text>
            <Text color="red.500">{market?.nbSells}</Text>
          </HStack>
        }
      />

      <AnalyticsRow
        title="Buyers / Sellers"
        value={
          <HStack spacing={1}>
            <Text color="green.500">{market?.nbBuyers}</Text>
            <Text textColor="textSecondary">/</Text>
            <Text color="red.500">{market?.nbSellers}</Text>
          </HStack>
        }
      />

      <AnalyticsRow
        title="Circulating Supply"
        value={
          market?.circulatingSupply !== undefined &&
          market?.totalSupply !== undefined
            ? `${formattedNum(market.circulatingSupply, {
                places: 2
              })} / ${formattedNum(market.totalSupply)}`
            : undefined
        }
      />

      <AnalyticsRow
        title="Token"
        value={shortenAddress(market?.baseToken.address)}
        link={
          market
            ? getBlockExplorer(
                market.baseToken.address,
                market.chain,
                'address'
              ).url
            : undefined
        }
      />

      <AnalyticsRow
        title="Market"
        value={shortenAddress(market?.marketAddress)}
        link={
          market
            ? getBlockExplorer(market.marketAddress, market.chain, 'address')
                .url
            : undefined
        }
      />

      <AnalyticsRow
        title="Creator"
        value={shortenAddress(market?.creator)}
        link={
          market
            ? getBlockExplorer(market.creator, market.chain, 'address').url
            : undefined
        }
      />
    </VStack>
  )
}

export default TokenMillDetailAnalytics
