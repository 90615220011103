import { useWallet } from '@solana/wallet-adapter-react'
import { useMutation } from '@tanstack/react-query'
import bs58 from 'bs58'
import useAuthMessage from 'hooks/authentication/useAuthMessage'
import useLogin from 'hooks/authentication/useLogin'
import useRefreshToken from 'hooks/authentication/useRefreshToken'
import { jwtDecode } from 'jwt-decode'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state/store'
import { AuthTokens, Chain } from 'types/dexbarn'
import { useAccount, useSignMessage } from 'wagmi'

import { clearTokens, updateTokens } from './actions'

// get auth tokens
export const useGetAuthTokens = (
  chain: Chain
): {
  address: string
  tokens: AuthTokens | undefined
} => {
  const account = useAccount()
  const wallet = useWallet()

  let address: string
  switch (chain) {
    case 'avalanche':
    case 'monad':
      address = account.address || ''
      break
    case 'solana':
      address = wallet.publicKey?.toBase58() || ''
      break
  }

  const tokens = useSelector(
    (state: RootState) => state.authentication.tokens[chain]?.[address]
  )

  return {
    address,
    tokens
  }
}

// returns true if the auth tokens are still valid
const getIsAuthTokenValid = (tokens: AuthTokens, account: string): boolean => {
  const decodedAccessToken = jwtDecode<{ exp: number; sub: string }>(
    tokens.accessToken
  )
  const now = new Date()
  const expiresDate = new Date(decodedAccessToken.exp * 1000)
  return (
    decodedAccessToken.sub.toLowerCase() === account.toLowerCase() &&
    now < expiresDate
  )
}

// verify that the access token is still valid and try to refresh it if it expired
// returns true if the local access token is valid, else false
const useRefreshTokenIfNeeded = () => {
  const dispatch = useDispatch()
  const refreshAccessToken = useRefreshToken()

  return useCallback(
    async (
      chain: Chain,
      address: string,
      tokens: AuthTokens
    ): Promise<AuthTokens | undefined> => {
      const decodedAccessToken = jwtDecode<{ exp: number; sub: string }>(
        tokens.accessToken
      )
      const now = new Date()
      const expiresDate = new Date(decodedAccessToken.exp * 1000)

      // the token we have is not for the currently connected wallet
      if (decodedAccessToken.sub.toLowerCase() !== address.toLowerCase()) {
        dispatch(clearTokens({ address, chain }))
        return undefined
      }

      // access token has expired
      if (now >= expiresDate) {
        if (!tokens.refreshToken) {
          return undefined
        }
        const decodedRefreshToken = jwtDecode<{ exp: number; sub: string }>(
          tokens.refreshToken
        )
        const refreshTokenExpiresDate = new Date(decodedRefreshToken.exp * 1000)
        if (now >= refreshTokenExpiresDate) {
          // refresh token has expired
          dispatch(clearTokens({ address, chain }))
          return undefined
        }
        try {
          const newTokens = await refreshAccessToken(tokens.refreshToken)
          dispatch(updateTokens({ address, chain, tokens: newTokens }))
          return newTokens
        } catch (err) {
          // refresh token didn't work, we'll ask for a new signature
          console.error(err)
          dispatch(clearTokens({ address, chain }))
          return undefined
        }
      }

      // token still valid
      return tokens
    },
    [dispatch, refreshAccessToken]
  )
}

export const useIsLoggedIn = (chain: Chain) => {
  const { address, tokens } = useGetAuthTokens(chain)

  return useMemo(() => {
    if (!tokens || !address) return false
    return getIsAuthTokenValid(tokens, address)
  }, [tokens, address])
}

// login the user when we don't have a valid access token already
export const useLoginIfNeeded = ({ chain }: { chain: Chain }) => {
  const { signMessageAsync: signMessageEVM } = useSignMessage()
  const { address: evmAddress } = useAccount()
  const { publicKey: solanaPublicKey, signMessage: signMessageSolana } =
    useWallet()
  const getAuthMessage = useAuthMessage()
  const login = useLogin()
  const refreshAccessToken = useRefreshTokenIfNeeded()

  const tokens = useSelector((state: RootState) => state.authentication.tokens)
  const dispatch = useDispatch()

  return useMutation({
    mutationFn: async (): Promise<AuthTokens | undefined> => {
      let userAddress: string
      let signature: string

      switch (chain) {
        case 'avalanche':
        case 'monad':
          if (!evmAddress || !signMessageEVM) {
            throw new Error("Can't sign message with EVM wallet")
          }
          userAddress = evmAddress
          break
        case 'solana':
          if (!solanaPublicKey || !signMessageSolana) {
            throw new Error("Can't sign message with Solana wallet")
          }
          userAddress = solanaPublicKey.toBase58()
          break
        default:
          throw new Error(`Unsupported chain: ${chain}`)
      }

      const currentTokens = tokens[chain]?.[userAddress]

      if (currentTokens) {
        try {
          const newTokens = await refreshAccessToken(
            chain,
            userAddress,
            currentTokens
          )
          if (newTokens) {
            dispatch(
              updateTokens({ address: userAddress, chain, tokens: newTokens })
            )
            return newTokens
          }
        } catch (err) {
          console.error('Failed to refresh token:', err)
          // Proceed to request a new signature
        }
      }

      const message = (await getAuthMessage(userAddress)).message

      switch (chain) {
        case 'avalanche':
        case 'monad':
          signature = await signMessageEVM({ message })
          break
        case 'solana':
          const encodedMessage = new TextEncoder().encode(message)
          const signatureBytes = await signMessageSolana?.(encodedMessage)
          if (!signatureBytes) {
            throw new Error('Failed to sign message')
          }
          signature = bs58.encode(signatureBytes)
          break
      }

      const newTokens = await login(userAddress, signature, message)
      dispatch(updateTokens({ address: userAddress, chain, tokens: newTokens }))
      return newTokens
    }
  })
}
