import { useInfiniteQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { Chain, DexbarnUserVestingPosition } from 'types/dexbarn'
import { PortfolioVestingPosition } from 'types/portfolio'

export interface UseGetUserVestingPositionsProps {
  chain: Chain
  pageSize?: number
  userAddress?: string
}

const useGetUserVestingPositions = ({
  chain,
  pageSize = 20,
  userAddress
}: UseGetUserVestingPositionsProps) => {
  const fetchUserVestingPositions = useSolbarnGet<DexbarnUserVestingPosition[]>(
    `/v2/vesting/${chain}/users/${userAddress}`
  )

  return useInfiniteQuery<PortfolioVestingPosition[]>({
    enabled: !!userAddress,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchUserVestingPositions({
        params: {
          pageNum: pageParam,
          pageSize
        }
      })

      return data
        .map(
          (position): PortfolioVestingPosition => ({
            amountReleased: Number(position.amountReleased),
            baseTokenLogoUrl: position.iconUrl || '',
            baseTokenPriceUsd: position.baseToken.priceUsd,
            baseTokenSymbol: position.baseToken.symbol,
            chain: position.chain,
            cliffDuration: position.cliffDuration,
            marketAddress: position.tokenMillMarketId,
            start: new Date(position.createdAt).getTime() / 1000,
            valueUsd:
              Number(position.vestingAmount) * position.baseToken.priceUsd,
            vestingAmount: Number(position.vestingAmount),
            vestingDuration: position.vestingDuration
          })
        )
        .filter((position) => position.vestingAmount > 0)
    },
    queryKey: ['GetUserVestingPositions', chain, pageSize, userAddress]
  })
}

export default useGetUserVestingPositions
