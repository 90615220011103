import { createAction } from '@reduxjs/toolkit'

import { FeeMode } from './reducer'

export const setAreTermsAccepted = createAction<boolean>(
  'settings/areTermsAccepted'
)

export const setOriginCountryCode = createAction<string>(
  'settings/originCountryCode'
)

export const setIsUKDisclaimerHidden = createAction<boolean>(
  'settings/isUKDisclaimerHidden'
)

export const setMaxTransactionFee = createAction<string>(
  'settings/maxTransactionFee'
)

export const setFeeMode = createAction<FeeMode>('settings/feeMode')

export const setIsHeaderCollapsed = createAction<boolean>(
  'settings/isHeaderCollapsed'
)
