import { useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { Chain } from 'types/dexbarn'
import { FeaturedMarket } from 'types/market'
import { getResizedImageUrl } from 'utils/image'

import useGetMarket from './useGetMarket'

interface UseGetFeaturedMarketProps {
  chain: Chain
  enabled: boolean
}

interface DexbarnFeaturedMarketResponse {
  marketAddress: string
}

const useGetFeaturedMarket = ({
  chain,
  enabled
}: UseGetFeaturedMarketProps) => {
  const fetchFeaturedMarket = useSolbarnGet<DexbarnFeaturedMarketResponse>(
    `/v2/markets/featured?chains=${chain}`
  )

  const { data: featuredMarketInfo, isLoading: isLoadingFeaturedMarket } =
    useQuery({
      enabled,
      queryFn: () => fetchFeaturedMarket(),
      queryKey: ['GetFeaturedMarket', chain]
    })

  const featuredMarketAddress = featuredMarketInfo?.marketAddress

  const { data: marketInfo, isLoading: isLoadingMarket } = useGetMarket({
    chain,
    enabled: !!featuredMarketAddress,
    filterBy: 'd1',
    marketAddress: featuredMarketAddress || ''
  })

  const featuredMarket = useMemo(() => {
    if (!marketInfo) return undefined

    return {
      baseMarketCapUsd: marketInfo.baseMarketCapUsd,
      baseTokenName: marketInfo.baseToken.name,
      baseTokenSymbol: marketInfo.baseToken.symbol,
      iconUrl: getResizedImageUrl(marketInfo.iconUrl || '') || '',
      marketAddress: marketInfo.marketAddress,
      marketCreatedAt: new Date(marketInfo.marketCreatedAt),
      numberAsks: marketInfo.numberAsks,
      numberBids: marketInfo.numberBids,
      quoteLiquidityUsd: marketInfo.quoteLiquidityUsd,
      volumeUsd: marketInfo.volumeUsd
    } satisfies FeaturedMarket
  }, [marketInfo])

  return {
    data: featuredMarket,
    isLoading: isLoadingFeaturedMarket || isLoadingMarket
  }
}

export default useGetFeaturedMarket
