import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useSolbarnClient } from 'hooks/useDexbarn'
import {
  AuthTokens,
  Chain,
  PatchMarketArgs,
  PatchMarketResponse
} from 'types/dexbarn'

const usePatchMarket = ({ chain }: { chain: Chain }) => {
  const solbarnClient = useSolbarnClient()
  const account = useAccountOnChain(chain)
  const addErrorPopup = useAddErrorPopup()

  return useMutation<
    PatchMarketResponse,
    Error,
    {
      args: PatchMarketArgs
      authTokens: AuthTokens
      chain: Chain
      marketAddress: string
    }
  >({
    mutationFn: async ({ args, authTokens, chain, marketAddress }) => {
      if (!account.address) {
        throw new Error('Wallet not connected')
      }

      const response = await solbarnClient.patch<PatchMarketResponse>(
        `/v2/markets/${chain}/${marketAddress}`,
        {
          description: args.description,
          discordUrl: args.discordUrl,
          iconUrl: args.iconUrl,
          telegramUrl: args.telegramUrl,
          twitterUrl: args.twitterUrl,
          websiteUrl: args.websiteUrl
        },
        {
          headers: { Authorization: `Bearer ${authTokens.accessToken}` }
        }
      )

      return response.data
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.data.detail) {
        addErrorPopup({
          subtitle: error.response?.data.detail,
          summary: 'Unable to patch market'
        })
      } else if (error instanceof Error) {
        addErrorPopup({
          subtitle: error.message,
          summary: 'Unable to patch market'
        })
      }
      console.error(error)
    }
  })
}

export default usePatchMarket
