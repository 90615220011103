import useGetMarketEVM from 'hooks/evm/useGetMarketEVM'
import useGetMarketSolana from 'solana/hooks/useGetMarketSolana'
import { Chain } from 'types/dexbarn'
import { SolbarnMarketQueryParam } from 'types/solbarn'

export interface UseGetMarketProps {
  chain: Chain
  filterBy: SolbarnMarketQueryParam.FilterBy
  marketAddress: string
}

const useGetMarket = ({
  chain,
  filterBy,
  marketAddress
}: UseGetMarketProps) => {
  const resultEVM = useGetMarketEVM({ chain, filterBy, marketAddress })
  const resultSolana = useGetMarketSolana({ chain, filterBy, marketAddress })

  switch (chain) {
    case 'avalanche':
    case 'monad':
      return resultEVM
    case 'solana':
      return resultSolana
  }
}

export default useGetMarket
