import { RawAxiosRequestConfig } from 'axios'
import { IS_TESTNET } from 'constants/chains'
import { solbarnClient, solbarnTestnetClient } from 'constants/dexbarn'
import { useCallback } from 'react'

export const useSolbarnClient = () => {
  return IS_TESTNET ? solbarnTestnetClient : solbarnClient
}

export const useSolbarnGet = <T = any, D = any>(
  url: string,
  config?: RawAxiosRequestConfig<D>
) => {
  const client = useSolbarnClient()
  return useCallback(
    async (overridesConfig?: RawAxiosRequestConfig<D>) => {
      const { data } = await client.get<T>(url, overridesConfig ?? config)
      return data
    },
    [client, url, config]
  )
}

export const useSolbarnPost = <T = any, D = any>(url: string, body?: D) => {
  const client = useSolbarnClient()
  return useCallback(async () => {
    const { data } = await client.post<T>(url, body)
    return data
  }, [client, url, body])
}
