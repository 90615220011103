import {
  Box,
  Divider,
  Grid,
  Hide,
  HStack,
  Show,
  Text,
  VStack
} from '@chakra-ui/react'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import useGetMarketStakingData from 'hooks/tokenmill/useGetMarketStakingData'
import useGetStakePosition from 'hooks/tokenmill/useGetStakePosition'
import debounce from 'lodash.debounce'
import TokenInfoGridElement from 'pages/TokenMillDetail/TokenInfoGridElement'
import TokenInfoSection from 'pages/TokenMillDetail/TokenInfoSection'
import TokenMillDetailHeader from 'pages/TokenMillDetail/TokenMillDetailHeader'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Chain } from 'types/dexbarn'
import { isSolanaAddress } from 'utils/addresses'
import { formattedNum } from 'utils/format'
import { isAddress } from 'viem'

import StakeFAQ from './StakeFAQ'
import StakeOverview from './StakeOverview'
import StakeUnstakePanel from './StakeUnstakePanel'

interface StakeProps {
  chain: Chain
  marketAddress: string
}

const Stake = ({ chain, marketAddress }: StakeProps) => {
  const { data: market, isLoading: isLoadingMarket } = useGetMarket({
    chain,
    filterBy: 'd1',
    marketAddress
  })

  const account = useAccountOnChain(chain)
  const isCreator =
    market?.creator.toLowerCase() === account.address?.toLowerCase()

  const {
    data: marketStakingData,
    isLoading: isLoadingMarketStakingData,
    refetch: refetchMarketStakingData
  } = useGetMarketStakingData({
    baseTokenAddress: market?.baseToken.address,
    chain,
    marketAddress
  })
  const debouncedRefetchMarketStakingData = debounce(
    refetchMarketStakingData,
    1000
  )

  const {
    data: userStakePosition,
    isLoading: isLoadingStakePosition,
    refetch: refetchStakePosition
  } = useGetStakePosition({
    baseTokenAddress: market?.baseToken.address,
    chain,
    marketAddress
  })
  const debouncedRefetchStakePosition = debounce(refetchStakePosition, 1000)

  const totalSupplyStakedPercentage =
    market && marketStakingData?.totalStakedAndLockedAmount
      ? (Number(marketStakingData.totalStakedAndLockedAmount.formatted) /
          market.totalSupply) *
        100
      : 0

  return (
    <Box maxW="1600px" margin="0 auto" minH="90svh">
      <Grid
        templateColumns={{ base: '1fr', lg: 'auto 1px 500px' }}
        alignItems="flex-start"
      >
        <VStack spacing={0}>
          <TokenMillDetailHeader
            marketAddress={marketAddress}
            chain={chain}
            market={market}
            isCreator={isCreator}
          />

          <Divider />

          <Show below="md">
            {market && (
              <Box w="full">
                <StakeUnstakePanel
                  chain={chain}
                  baseToken={market.baseToken}
                  baseTokenLogoUrl={market.baseTokenLogoUrl}
                  marketAddress={marketAddress}
                  userStakePosition={userStakePosition}
                  isLoadingUserStakePosition={isLoadingStakePosition}
                  onStakeSuccess={() => {
                    debouncedRefetchStakePosition()
                    debouncedRefetchMarketStakingData()
                  }}
                  onUnstakeSuccess={() => {
                    debouncedRefetchStakePosition()
                    debouncedRefetchMarketStakingData()
                  }}
                />

                <Divider />

                <StakeOverview
                  chain={chain}
                  userStakePosition={userStakePosition}
                  baseToken={market.baseToken}
                  quoteToken={market.quoteCurrency}
                  isLoadingUserStakePosition={isLoadingStakePosition}
                  marketAddress={marketAddress}
                  onClaimPendingRewardsSuccess={() => {
                    debouncedRefetchStakePosition()
                  }}
                />
              </Box>
            )}
            <Divider />
          </Show>

          <VStack
            align="flex-start"
            w="full"
            p={{ base: 4, md: 6 }}
            spacing={{ base: 4, md: 6 }}
          >
            <HStack
              w="full"
              rowGap={{ base: 4, md: 12 }}
              columnGap={{ base: 8, md: 12 }}
              flexWrap="wrap"
            >
              <TokenInfoGridElement
                title="Total Staked"
                value={
                  marketStakingData?.totalStakedAndLockedAmount
                    ? `${formattedNum(
                        marketStakingData.totalStakedAndLockedAmount.formatted
                      )} ${market?.baseToken.symbol}`
                    : '0'
                }
                isLoading={isLoadingMarket || isLoadingMarketStakingData}
                tooltipLabel={
                  marketStakingData &&
                  marketStakingData.totalLockedAmount &&
                  marketStakingData.totalStakedAmount ? (
                    <VStack align="flex-start" spacing={1} p={2}>
                      <Text
                        textColor="white"
                        _dark={{ textColor: 'black' }}
                      >{`Staked: ${formattedNum(
                        marketStakingData.totalStakedAmount.formatted
                      )} ${market?.baseToken.symbol}`}</Text>
                      <Text
                        textColor="white"
                        _dark={{ textColor: 'black' }}
                      >{`Vested: ${formattedNum(
                        marketStakingData.totalLockedAmount.formatted
                      )} ${market?.baseToken.symbol}`}</Text>
                    </VStack>
                  ) : undefined
                }
              />
              <TokenInfoGridElement
                title="% Supply Staked"
                value={
                  formattedNum(totalSupplyStakedPercentage, { places: 2 }) + '%'
                }
                isLoading={isLoadingMarket || isLoadingMarketStakingData}
              />
              <TokenInfoGridElement
                title="Staking Fee Share"
                isLoading={isLoadingMarket}
                value={
                  market?.fees
                    ? `${formattedNum(market.fees.stakingFeeShare / 100, {
                        places: 2
                      })}%`
                    : '0%'
                }
              />
              <TokenInfoGridElement
                title="Fees (24H)"
                isLoading={isLoadingMarket}
                value={
                  market?.stakingFeesUsd
                    ? formattedNum(market.stakingFeesUsd, {
                        places: 2,
                        usd: true
                      })
                    : '$0'
                }
              />
              <TokenInfoGridElement
                title="Staking APR"
                isLoading={isLoadingMarket}
                value={
                  market?.stakingApr
                    ? `${formattedNum(market.stakingApr * 100, {
                        places: 2
                      })}%`
                    : '0%'
                }
              />
            </HStack>
          </VStack>

          <Hide below="md">
            <Divider />
            <StakeFAQ />
          </Hide>
        </VStack>

        <Hide below="md">
          <Divider orientation="vertical" h="full" />
        </Hide>

        <Hide below="md">
          {market && (
            <Box>
              <TokenInfoSection
                chain={chain}
                isCreator={isCreator}
                market={market}
              />

              <Divider />

              <StakeUnstakePanel
                chain={chain}
                baseToken={market.baseToken}
                baseTokenLogoUrl={market.baseTokenLogoUrl}
                marketAddress={marketAddress}
                userStakePosition={userStakePosition}
                isLoadingUserStakePosition={isLoadingStakePosition}
                onStakeSuccess={() => {
                  debouncedRefetchStakePosition()
                  debouncedRefetchMarketStakingData()
                }}
                onUnstakeSuccess={() => {
                  debouncedRefetchStakePosition()
                  debouncedRefetchMarketStakingData()
                }}
              />

              <Divider />

              <StakeOverview
                chain={chain}
                userStakePosition={userStakePosition}
                baseToken={market.baseToken}
                quoteToken={market.quoteCurrency}
                isLoadingUserStakePosition={isLoadingStakePosition}
                marketAddress={marketAddress}
                onClaimPendingRewardsSuccess={() => {
                  debouncedRefetchStakePosition()
                }}
              />
            </Box>
          )}
        </Hide>
      </Grid>

      <Divider />

      <Show below="md">
        <StakeFAQ />
        <Divider />
      </Show>
    </Box>
  )
}

const StakeWithRedirect = () => {
  const { chain: chainParam, marketAddress } = useParams()

  if (
    !marketAddress ||
    (chainParam !== 'avalanche' &&
      chainParam !== 'solana' &&
      chainParam !== 'monad')
  ) {
    return <Navigate to="/404" />
  }

  const chain = chainParam as Chain

  switch (chain) {
    case 'avalanche':
    case 'monad':
      if (!isAddress(marketAddress)) {
        return <Navigate to="/404" />
      }
      break
    case 'solana':
      if (!isSolanaAddress(marketAddress)) {
        return <Navigate to="/404" />
      }
      break
  }

  return <Stake marketAddress={marketAddress} chain={chain} />
}

export default StakeWithRedirect
