import { Hex } from 'viem'

import { TokenMillChainId } from './chains'

type Addresses = {
  [key in TokenMillChainId]: Hex | undefined
}

export const TM_FACTORY_ADDRESS: Addresses = {
  [TokenMillChainId.FUJI]: '0x5fEE6e1B5DDF4CBd7538b975CA48eE0c5d4F1142',
  [TokenMillChainId.AVALANCHE]: '0x501ee2D4AA611C906F785e10cC868e145183FCE4',
  [TokenMillChainId.SOLANA]: undefined,
  [TokenMillChainId.SOLANA_DEVNET]: undefined,
  [TokenMillChainId.MONAD_TESTNET]: '0x501ee2D4AA611C906F785e10cC868e145183FCE4'
}

export const TM_LENS: Addresses = {
  [TokenMillChainId.FUJI]: '0x1dA46DE0b7766865db00563E13864e7932BCA60E',
  [TokenMillChainId.AVALANCHE]: '0x4412d3CA644bAF2a3695662096d581f41986DDF1',
  [TokenMillChainId.SOLANA]: undefined,
  [TokenMillChainId.SOLANA_DEVNET]: undefined,
  [TokenMillChainId.MONAD_TESTNET]: '0x1713b36423f91A896D7D9798A9e58bab18e711dc'
}

export const TM_ROUTER: Addresses = {
  [TokenMillChainId.FUJI]: '0xFE77E0F4E1533cF4Fef2d6E751845bBB67ecA372',
  [TokenMillChainId.AVALANCHE]: '0x1b1f2Bfc5e551b955F2a3F973876cEE917FB4d05',
  [TokenMillChainId.SOLANA]: undefined,
  [TokenMillChainId.SOLANA_DEVNET]: undefined,
  [TokenMillChainId.MONAD_TESTNET]: '0x1b1f2Bfc5e551b955F2a3F973876cEE917FB4d05'
}
export const TM_STAKING: Addresses = {
  [TokenMillChainId.FUJI]: '0xC8f8b84FB76c9250A15934a79CE05Ae9781E606F',
  [TokenMillChainId.AVALANCHE]: '0xF2C15BD1BF3d7863A4b6d72F89758c18489794Aa',
  [TokenMillChainId.SOLANA]: undefined,
  [TokenMillChainId.SOLANA_DEVNET]: undefined,
  [TokenMillChainId.MONAD_TESTNET]: '0xF2C15BD1BF3d7863A4b6d72F89758c18489794Aa'
}
