import { useInfiniteQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { Chain, ChainFilter } from 'types/dexbarn'
import { PortfolioStakingPosition } from 'types/portfolio'

export interface UseGetUserStakingPositionsProps {
  chain: ChainFilter
  pageSize?: number
  userAddress?: string
}

interface DexbarnUserStakingPosition {
  amountStaked: string
  baseToken: {
    priceUsd: number
    symbol: string
  }
  chain: Chain
  iconUrl: string | null
  tokenMillMarketId: string
}

const useGetUserStakingPositions = ({
  chain,
  pageSize = 20,
  userAddress
}: UseGetUserStakingPositionsProps) => {
  const fetchUserStakingPositions = useSolbarnGet<DexbarnUserStakingPosition[]>(
    `/v2/staking/${chain}/users/${userAddress}`
  )

  return useInfiniteQuery<PortfolioStakingPosition[]>({
    enabled: !!userAddress,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchUserStakingPositions({
        params: {
          pageNum: pageParam,
          pageSize
        }
      })

      return data
        .map(
          (position): PortfolioStakingPosition => ({
            amountStaked: Number(position.amountStaked),
            baseTokenLogoUrl: position.iconUrl || '',
            baseTokenPriceUsd: position.baseToken.priceUsd,
            baseTokenSymbol: position.baseToken.symbol,
            chain: position.chain,
            marketAddress: position.tokenMillMarketId,
            valueUsd:
              Number(position.amountStaked) * position.baseToken.priceUsd
          })
        )
        .filter((position) => position.amountStaked > 0)
    },
    queryKey: ['GetUserStakingPositions', chain, pageSize, userAddress]
  })
}

export default useGetUserStakingPositions
