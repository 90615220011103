import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useSolbarnClient } from 'hooks/useDexbarn'
import { useCallback } from 'react'

interface Web3MessageResponse {
  message: string
}

const useAuthMessage = (): ((
  address: string
) => Promise<Web3MessageResponse>) => {
  const solbarnClient = useSolbarnClient()
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async (address: string): Promise<Web3MessageResponse> => {
      try {
        const body = {
          address
        }

        const response = await solbarnClient.post<Web3MessageResponse>(
          `/v2/auth/web3-message`,
          body
        )

        return response.data
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup({
            subtitle: e.message,
            summary: 'Unable to authenticate'
          })
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [solbarnClient, addErrorPopup]
  )
}

export default useAuthMessage
