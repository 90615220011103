import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useSolbarnClient } from 'hooks/useDexbarn'
import { useCallback } from 'react'
import { AuthTokens } from 'types/dexbarn'

const useRefreshToken = (): ((refreshToken: string) => Promise<AuthTokens>) => {
  const solbarnClient = useSolbarnClient()
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async (refreshToken: string): Promise<AuthTokens> => {
      try {
        const response = await solbarnClient.get<AuthTokens>(
          `/v2/auth/refresh-token`,
          {
            headers: { Authorization: `Bearer ${refreshToken}` }
          }
        )

        return {
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken
        }
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup({
            subtitle: e.message,
            summary: 'Unable to refresh auth token'
          })
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [solbarnClient, addErrorPopup]
  )
}

export default useRefreshToken
