import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useSolbarnClient } from 'hooks/useDexbarn'
import { useCallback } from 'react'
import { AuthTokens } from 'types/dexbarn'

const useLogin = (): ((
  address: string,
  signature: string,
  message: string
) => Promise<AuthTokens>) => {
  const solbarnClient = useSolbarnClient()
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async (
      address: string,
      signature: string,
      message: string
    ): Promise<AuthTokens> => {
      try {
        const body = {
          address,
          message,
          signature
        }

        const response = await solbarnClient.post<AuthTokens>(
          `/v2/auth/login`,
          body
        )

        return {
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken
        }
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup({
            subtitle: e.message,
            summary: 'Unable to authenticate'
          })
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [solbarnClient, addErrorPopup]
  )
}

export default useLogin
