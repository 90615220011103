import useGetPendingReferrerFeesEVM from 'hooks/evm/useGetPendingReferrerFees'
import useGetPendingReferrerFeesSolana from 'solana/hooks/useGetPendingReferrerFeesSolana'
import { Chain } from 'types/dexbarn'

export interface UseGetPendingReferrerFeesProps {
  chain: Chain
  quoteTokenAddress: string
}

const useGetPendingReferrerFees = (props: UseGetPendingReferrerFeesProps) => {
  const resultEVM = useGetPendingReferrerFeesEVM(props)
  const resultSolana = useGetPendingReferrerFeesSolana(props)

  switch (props.chain) {
    case 'avalanche':
    case 'monad':
      return resultEVM
    case 'solana':
      return resultSolana
  }
}

export default useGetPendingReferrerFees
