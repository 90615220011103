import AvalancheIcon from 'assets/avalanche.svg'
import MonadIcon from 'assets/monad.jpg'
import { Chain as DexbarnChain } from 'types/dexbarn'
import {
  avalanche as wagmiAvalanche,
  avalancheFuji,
  avalancheFuji as wagmiAvalancheFuji,
  Chain as WagmiChain,
  monadTestnet as wagmiMonadTestnet
} from 'wagmi/chains'

export enum TokenMillChainId {
  FUJI = avalancheFuji.id,
  AVALANCHE = wagmiAvalanche.id,
  MONAD_TESTNET = wagmiMonadTestnet.id,
  SOLANA_DEVNET = 901,
  SOLANA = 900
}

type Chain = {
  dexbarnId: DexbarnChain
  iconUrl: string
  slug: string
} & WagmiChain

const avalanche: Chain = {
  ...wagmiAvalanche,
  blockExplorers: {
    default: {
      apiUrl: 'https://api.snowscan.xyz/api',
      name: 'SnowScan',
      url: 'https://snowscan.xyz'
    }
  },
  dexbarnId: 'avalanche',
  iconUrl: AvalancheIcon,
  slug: 'avalanche'
}

const fuji: Chain = {
  ...wagmiAvalancheFuji,
  blockExplorers: {
    default: {
      apiUrl: 'https://api-testnet.snowscan.xyz/api',
      name: 'SnowScan',
      url: 'https://testnet.snowscan.xyz'
    }
  },
  dexbarnId: 'avalanche',
  iconUrl: AvalancheIcon,
  slug: 'fuji'
}

const monadTestnet: Chain = {
  ...wagmiMonadTestnet,
  dexbarnId: 'monad',
  iconUrl: MonadIcon,
  slug: 'monad-testnet'
}

export const IS_TESTNET = process.env.REACT_APP_IS_TESTNET === 'true'
export const IS_MONAD_ENABLED = process.env.REACT_APP_ENABLE_MONAD === 'true'

export const DEFAULT_EVM_CHAIN: DexbarnChain = IS_MONAD_ENABLED
  ? 'monad'
  : 'avalanche'

export const chains = IS_MONAD_ENABLED
  ? ([monadTestnet] as const)
  : IS_TESTNET
    ? ([fuji] as const)
    : ([avalanche] as const)
