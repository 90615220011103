import { BN, Idl, Program } from '@coral-xyz/anchor'
import * as spl from '@solana/spl-token'
import {
  Connection,
  Keypair,
  PublicKey,
  TransactionInstruction
} from '@solana/web3.js'
import { VestingArgs } from 'hooks/tokenmill/useCreateMarketAndToken'
import TokenMillIdl from 'solana/idl/token_mill.json'
import { parseUnits } from 'viem'

import { getTokenProgramID } from './token'

export const getCreateVestingInstructions = async ({
  baseTokenAddress,
  connection,
  marketAddress,
  vestingArgs,
  wallet
}: {
  baseTokenAddress: string
  connection: Connection
  marketAddress: string
  vestingArgs: VestingArgs
  wallet: PublicKey
}): Promise<{
  instructions: TransactionInstruction[]
  vestingPlanKeypair: Keypair
}> => {
  if (!wallet) throw new Error('Wallet not connected')
  if (!vestingArgs) throw new Error('vestingArgs not provided')

  const program = new Program(TokenMillIdl as Idl, {
    connection
  })
  const market = new PublicKey(marketAddress)
  const baseTokenMint = new PublicKey(baseTokenAddress)

  const staking = PublicKey.findProgramAddressSync(
    [Buffer.from('market_staking'), market.toBuffer()],
    program.programId
  )[0]

  const stakePosition = PublicKey.findProgramAddressSync(
    [Buffer.from('stake_position'), market.toBuffer(), wallet.toBuffer()],
    program.programId
  )[0]

  const baseTokenProgramID = await getTokenProgramID(
    baseTokenAddress,
    connection
  )

  const marketBaseTokenAta = spl.getAssociatedTokenAddressSync(
    baseTokenMint,
    market,
    true,
    baseTokenProgramID
  )

  const userBaseTokenAta = spl.getAssociatedTokenAddressSync(
    baseTokenMint,
    wallet,
    true,
    baseTokenProgramID
  )

  const vestingPlanKeypair = Keypair.generate()

  const instructions: TransactionInstruction[] = []

  const stakingAccountInfo = await connection.getAccountInfo(staking)
  if (!stakingAccountInfo) {
    const createStakingIx = await program.methods
      .createStaking()
      .accounts({
        market,
        payer: wallet,
        staking
      })
      .instruction()
    instructions.push(createStakingIx)
  }

  const stakePositionAccountInfo =
    await connection.getAccountInfo(stakePosition)
  if (!stakePositionAccountInfo) {
    const createStakePositionIx = await program.methods
      .createStakePosition()
      .accounts({
        market,
        stakePosition,
        user: wallet
      })
      .instruction()
    instructions.push(createStakePositionIx)
  }

  const vestingAmount = new BN(parseUnits(vestingArgs.baseAmount.toString(), 6))
  const now = new BN(Math.floor(Date.now() / 1000))
  const cliffDuration = new BN(1)

  let vestingDuration = new BN(0)
  switch (vestingArgs.vestingType) {
    case '1hour':
      vestingDuration = new BN(60 * 60)
      break
    case '1day':
      vestingDuration = new BN(24 * 60 * 60)
      break
    case '3days':
      vestingDuration = new BN(3 * 24 * 60 * 60)
      break
    case '1week':
      vestingDuration = new BN(7 * 24 * 60 * 60)
      break
  }

  const createVestingPlanIx = await program.methods
    .createVestingPlan(now, vestingAmount, vestingDuration, cliffDuration)
    .accounts({
      baseTokenMint,
      baseTokenProgram: baseTokenProgramID,
      market,
      marketBaseTokenAta,
      stakePosition,
      staking,
      user: wallet,
      userBaseTokenAta,
      vestingPlan: vestingPlanKeypair.publicKey
    })
    .instruction()

  instructions.push(createVestingPlanIx)

  return { instructions, vestingPlanKeypair }
}

interface GetReleaseTokensInstructionsProps {
  baseTokenAddress: string
  connection: Connection
  marketAddress: string
  vestingPlanId: string
  walletPublicKey: PublicKey
}

export const getReleaseTokensInstructions = async ({
  baseTokenAddress,
  connection,
  marketAddress,
  vestingPlanId,
  walletPublicKey
}: GetReleaseTokensInstructionsProps): Promise<TransactionInstruction[]> => {
  const program = new Program(TokenMillIdl as Idl, {
    connection
  })

  const market = new PublicKey(marketAddress)
  const baseTokenMint = new PublicKey(baseTokenAddress)
  const vestingPlan = new PublicKey(vestingPlanId)

  const baseTokenProgramID = await getTokenProgramID(
    baseTokenAddress,
    connection
  )

  const marketBaseTokenAta = spl.getAssociatedTokenAddressSync(
    baseTokenMint,
    market,
    true,
    baseTokenProgramID
  )

  const userBaseTokenAta = spl.getAssociatedTokenAddressSync(
    baseTokenMint,
    walletPublicKey,
    true,
    baseTokenProgramID
  )

  const staking = PublicKey.findProgramAddressSync(
    [Buffer.from('market_staking'), market.toBuffer()],
    program.programId
  )[0]

  const stakePosition = PublicKey.findProgramAddressSync(
    [
      Buffer.from('stake_position'),
      market.toBuffer(),
      walletPublicKey.toBuffer()
    ],
    program.programId
  )[0]

  const instruction = await program.methods
    .release()
    .accountsPartial({
      baseTokenMint,
      baseTokenProgram: baseTokenProgramID,
      market,
      marketBaseTokenAta,
      stakePosition,
      staking,
      user: walletPublicKey,
      userBaseTokenAta,
      vestingPlan
    })
    .instruction()

  return [instruction]
}
