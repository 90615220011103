import {
  Button,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Skeleton,
  Td,
  Text,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import CopyReferredURLButton from 'components/CopyReferredURLButton'
import CurrencyLogo from 'components/CurrencyLogo'
import LoginModal from 'components/LoginModal'
import Web3Button from 'components/Web3Button'
import { WNATIVE } from 'constants/token'
import useClaimReferrerFees from 'hooks/referrals/useClaimReferrerFees'
import useDeleteReferralCode from 'hooks/referrals/useDeleteReferralCode'
import useGetPendingReferrerFees from 'hooks/referrals/useGetPendingReferrerFees'
import usePatchReferralCode from 'hooks/referrals/usePatchReferralCode'
import debounce from 'lodash.debounce'
import React, { useState } from 'react'
import { useGetAuthTokens, useIsLoggedIn } from 'state/authentication/hooks'
import { AuthTokens, Chain } from 'types/dexbarn'
import { shortenAddress } from 'utils/addresses'
import { getChainId } from 'utils/chains'
import { formattedNum } from 'utils/format'

export interface ReferralTableRowProps {
  chain: Chain
  isLoadingUserReferralCodes: boolean
  nativeCurrencySymbol: string
  onDeleteReferralCodeSuccess: () => void
  referralCode: string | undefined
  walletAddress: string | undefined
}

const ReferralTableRow = ({
  chain,
  isLoadingUserReferralCodes,
  nativeCurrencySymbol,
  onDeleteReferralCodeSuccess,
  referralCode: initialReferralCode,
  walletAddress
}: ReferralTableRowProps) => {
  const chainId = getChainId(chain)
  const quoteTokenAddress = WNATIVE[chainId].address

  const {
    isOpen: isLoginModalOpen,
    onClose: onLoginModalClose,
    onOpen: onLoginModalOpen
  } = useDisclosure()

  const [referralCodeInput, setReferralCodeInput] = useState<string>('')
  const [pendingAction, setPendingAction] = useState<'create' | 'delete'>()

  const {
    data: patchReferralCodeResponse,
    isPending: isPatchingReferralCode,
    mutateAsync: patchReferralCodeAsync,
    reset: resetPatchReferralCode
  } = usePatchReferralCode({ chain })

  const {
    isPending: isDeletingReferralCode,
    mutateAsync: deleteReferralCodeAsync
  } = useDeleteReferralCode({ chain })

  const isLoggedIn = useIsLoggedIn(chain)
  const { tokens: authTokens } = useGetAuthTokens(chain)

  const handleCreateReferralCode = async (authTokens?: AuthTokens) => {
    if (!isLoggedIn || !authTokens) {
      setPendingAction('create')
      onLoginModalOpen()
      return
    }

    await patchReferralCodeAsync({
      args: { referralCode: referralCodeInput },
      authTokens
    })
    setReferralCodeInput('')
  }

  const handleDeleteReferralCode = async (authTokens?: AuthTokens) => {
    if (!isLoggedIn || !authTokens) {
      setPendingAction('delete')
      onLoginModalOpen()
      return
    }

    try {
      await deleteReferralCodeAsync({ authTokens })
      resetPatchReferralCode()
      onDeleteReferralCodeSuccess()
    } catch {}
  }

  const handleLoginSuccess = async (tokens: AuthTokens) => {
    switch (pendingAction) {
      case 'create':
        await handleCreateReferralCode(tokens)
        break
      case 'delete':
        await handleDeleteReferralCode(tokens)
        break
    }
    setPendingAction(undefined)
  }

  const referralCode = patchReferralCodeResponse?.code || initialReferralCode

  const {
    isLoadingPendingReferrerFees,
    pendingReferrerFees,
    refetchPendingReferrerFees
  } = useGetPendingReferrerFees({ chain, quoteTokenAddress })
  const debouncedRefetchPendingReferrerFees = debounce(
    refetchPendingReferrerFees,
    1000
  )

  const { claimAsync, isClaiming } = useClaimReferrerFees({
    chain,
    onClaimSuccess: debouncedRefetchPendingReferrerFees,
    quoteTokenAddress
  })

  return (
    <Tr>
      <Td pl={0}>
        <HStack>
          <CurrencyLogo
            boxSize={6}
            symbol={nativeCurrencySymbol}
            rounded="full"
          />
          <Text>{shortenAddress(walletAddress) || '--'}</Text>
        </HStack>
      </Td>
      <Td>
        <Skeleton isLoaded={!isLoadingUserReferralCodes}>
          {referralCode ? (
            <HStack spacing={4}>
              <Text>{referralCode}</Text>
              <CopyReferredURLButton
                chain={chain}
                code={referralCode}
                buttonSize="xs"
              />
            </HStack>
          ) : (
            '--'
          )}
        </Skeleton>
      </Td>
      <Td isNumeric>
        <Skeleton isLoaded={!isLoadingPendingReferrerFees}>
          <Text>{`${formattedNum(
            pendingReferrerFees?.formatted || '0'
          )} ${nativeCurrencySymbol}`}</Text>
        </Skeleton>
      </Td>
      <Td pr={0} isNumeric>
        <LoginModal
          isOpen={isLoginModalOpen}
          onClose={onLoginModalClose}
          onLoginSuccess={handleLoginSuccess}
          chain={chain}
          type="referral"
        />

        {referralCode ? (
          <HStack justify="flex-end">
            <Button
              size="sm"
              variant="boxShadowFlat"
              isLoading={isClaiming}
              loadingText="Claiming"
              _dark={{
                bg: 'bgSecondary'
              }}
              isDisabled={
                !claimAsync || pendingReferrerFees?.value === BigInt(0)
              }
              onClick={() => claimAsync?.()}
            >
              Claim Fees
            </Button>
            <Popover>
              <PopoverTrigger>
                <Button size="sm" colorScheme="red">
                  Delete Code
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  <Text>
                    Are you sure you want to delete this referral code?
                  </Text>
                </PopoverBody>
                <PopoverFooter>
                  <Button
                    size="sm"
                    colorScheme="red"
                    isLoading={isDeletingReferralCode}
                    onClick={() => handleDeleteReferralCode(authTokens)}
                  >
                    Yes
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </HStack>
        ) : (
          <HStack justify="flex-end">
            <Input
              size="sm"
              placeholder="Type in any code"
              maxW="250px"
              value={referralCodeInput}
              onChange={(e) => setReferralCodeInput(e.currentTarget.value)}
            />
            <Web3Button
              chain={chain}
              variant="boxShadowFlat"
              _dark={{
                bg: 'bgSecondary'
              }}
              size="sm"
              onClick={() => handleCreateReferralCode(authTokens)}
              isLoading={isPatchingReferralCode}
              loadingText="Creating Code"
              isDisabled={referralCodeInput.length === 0}
            >
              Create Code
            </Web3Button>
          </HStack>
        )}
      </Td>
    </Tr>
  )
}

export default ReferralTableRow
