import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { Chain, MarketData } from 'types/dexbarn'
import { SolbarnMarketQueryParam } from 'types/solbarn'

interface UseGetMarketProps {
  chain: Chain
  enabled: boolean
  filterBy: SolbarnMarketQueryParam.FilterBy
  marketAddress: string
}

const useGetMarket = ({
  chain,
  enabled,
  filterBy,
  marketAddress
}: UseGetMarketProps) => {
  const fetchMarket = useSolbarnGet<MarketData>(
    `/v2/markets/${chain}/${marketAddress}?filterBy=${filterBy}`
  )

  return useQuery<MarketData>({
    enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchMarket(),
    queryKey: ['GetMarket', marketAddress, chain, filterBy]
  })
}

export default useGetMarket
