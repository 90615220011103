import { useInfiniteQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { Chain, MarketRecentActivity } from 'types/dexbarn'

interface UseGetMarketRecentActivityParams {
  address: string
  chain: Chain
  pageSize?: number
}

const useGetMarketRecentActivity = ({
  address,
  chain,
  pageSize = 20
}: UseGetMarketRecentActivityParams) => {
  const fetchRecentActivity = useSolbarnGet<MarketRecentActivity[]>(
    `/v2/markets/${chain}/${address}/recent-activity`
  )

  return useInfiniteQuery<MarketRecentActivity[]>({
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      fetchRecentActivity({
        params: {
          pageNum: pageParam,
          pageSize
        }
      }),
    queryKey: ['GetRecentActivity', chain, address, pageSize]
  })
}

export default useGetMarketRecentActivity
