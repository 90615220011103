import { useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { useReferralStorage } from 'state/referral'
import { Chain } from 'types/dexbarn'
import { isSolanaAddress } from 'utils/addresses'
import { getChainType } from 'utils/chains'
import { isAddress } from 'viem'

const useGetReferrerAddressOnChain = ({
  chain,
  enabled
}: {
  chain: Chain
  enabled: boolean
}) => {
  const chainType = getChainType(chain)
  const { referralState } = useReferralStorage()
  const referralCode = referralState?.[chainType].code

  const fetchReferrerAddress = useSolbarnGet<string>(
    `/v2/users/referral-codes/${referralCode}`
  )

  return useQuery({
    enabled: enabled && !!referralCode,
    queryFn: async () => {
      const referrerAddress = await fetchReferrerAddress()

      if (isAddress(referrerAddress) && chainType === 'evm') {
        return referrerAddress
      }

      if (isSolanaAddress(referrerAddress) && chainType === 'solana') {
        return referrerAddress
      }

      return undefined
    },
    queryKey: ['getReferrerAddress', chain, referralCode]
  })
}

export default useGetReferrerAddressOnChain
