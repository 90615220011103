import { Chain } from 'types/dexbarn'
import { PricePoint } from 'utils/bondingCurves'

export const DEFAULT_ASK_PRICES: {
  [chain in Chain]: PricePoint[]
} = {
  avalanche: [
    { price: 0.000002, supply: 0 },
    { price: 0.0000023, supply: 100000000 },
    { price: 0.0000028, supply: 200000000 },
    { price: 0.0000035, supply: 300000000 },
    { price: 0.0000095, supply: 400000000 },
    { price: 0.000028, supply: 500000000 },
    { price: 0.000075, supply: 600000000 },
    { price: 0.00022, supply: 700000000 },
    { price: 0.0006, supply: 800000000 },
    { price: 0.0018, supply: 900000000 },
    { price: 0.005, supply: 1000000000 }
  ],
  monad: [
    { price: 0.000002, supply: 0 },
    { price: 0.0000023, supply: 100000000 },
    { price: 0.0000028, supply: 200000000 },
    { price: 0.0000035, supply: 300000000 },
    { price: 0.0000095, supply: 400000000 },
    { price: 0.000028, supply: 500000000 },
    { price: 0.000075, supply: 600000000 },
    { price: 0.00022, supply: 700000000 },
    { price: 0.0006, supply: 800000000 },
    { price: 0.0018, supply: 900000000 },
    { price: 0.005, supply: 1000000000 }
  ],
  solana: [
    { price: 0.0000004, supply: 0 },
    { price: 0.00000045, supply: 100000000 },
    { price: 0.00000055, supply: 200000000 },
    { price: 0.0000007, supply: 300000000 },
    { price: 0.0000019, supply: 400000000 },
    { price: 0.0000056, supply: 500000000 },
    { price: 0.000015, supply: 600000000 },
    { price: 0.000044, supply: 700000000 },
    { price: 0.00012, supply: 800000000 },
    { price: 0.00036, supply: 900000000 },
    { price: 0.001, supply: 1000000000 }
  ]
}

export const DEFAULT_BID_PRICES: {
  [chain in Chain]: PricePoint[]
} = {
  avalanche: [
    { price: 0.00000196, supply: 0 },
    { price: 0.000002254, supply: 100000000 },
    { price: 0.000002744, supply: 200000000 },
    { price: 0.00000343, supply: 300000000 },
    { price: 0.00000931, supply: 400000000 },
    { price: 0.00002744, supply: 500000000 },
    { price: 0.0000735, supply: 600000000 },
    { price: 0.0002156, supply: 700000000 },
    { price: 0.000588, supply: 800000000 },
    { price: 0.001764, supply: 900000000 },
    { price: 0.0049, supply: 1000000000 }
  ],
  monad: [
    { price: 0.00000196, supply: 0 },
    { price: 0.000002254, supply: 100000000 },
    { price: 0.000002744, supply: 200000000 },
    { price: 0.00000343, supply: 300000000 },
    { price: 0.00000931, supply: 400000000 },
    { price: 0.00002744, supply: 500000000 },
    { price: 0.0000735, supply: 600000000 },
    { price: 0.0002156, supply: 700000000 },
    { price: 0.000588, supply: 800000000 },
    { price: 0.001764, supply: 900000000 },
    { price: 0.0049, supply: 1000000000 }
  ],
  solana: [
    { price: 0.000000392, supply: 0 },
    { price: 0.000000441, supply: 100000000 },
    { price: 0.000000539, supply: 200000000 },
    { price: 0.000000686, supply: 300000000 },
    { price: 0.000001862, supply: 400000000 },
    { price: 0.000005488, supply: 500000000 },
    { price: 0.0000147, supply: 600000000 },
    { price: 0.00004312, supply: 700000000 },
    { price: 0.0001176, supply: 800000000 },
    { price: 0.0003528, supply: 900000000 },
    { price: 0.00098, supply: 1000000000 }
  ]
}
