import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Link,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react'
import CrownIcon from 'assets/crown_mill.png'
import CurrencyLogo from 'components/CurrencyLogo'
import useGetFeaturedMarket from 'hooks/barn/useGetFeaturedMarket'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Chain } from 'types/dexbarn'
import {
  formatNumberNoDecimalsToK,
  formatShortDistanceToNow
} from 'utils/format'

interface KingOfTheMillSectionProps {
  chain: Chain
}

const KingOfTheMillSection = ({ chain }: KingOfTheMillSectionProps) => {
  const { data: market, isLoading } = useGetFeaturedMarket({
    chain,
    enabled: true
  })

  return (
    <VStack spacing={4}>
      <Heading size="md" textTransform="none" textColor="accent.500">
        King of the Mill
      </Heading>

      {isLoading ? (
        <Box p={4}>
          <HStack spacing={4}>
            <Skeleton
              h={{ base: '60px', md: '80px' }}
              w={{ base: '60px', md: '80px' }}
              borderRadius="full"
            />
            <VStack align="flex-start" spacing={0} flex={1}>
              <Skeleton h="20px" w="150px" />
              <HStack mt={4} w="full">
                <Skeleton h="20px" w="80px" />
                <Skeleton h="20px" w="80px" />
                <Skeleton h="20px" w="80px" />
              </HStack>
              <HStack mt={2} w="full">
                <Skeleton h="20px" w="80px" />
              </HStack>
            </VStack>
          </HStack>
        </Box>
      ) : market ? (
        <Link
          as={NavLink}
          to={`/${chain}/${market.marketAddress}`}
          bg="bgSecondary"
          p={4}
          _hover={{
            bg: 'hover',
            textDecoration: 'none'
          }}
        >
          <HStack spacing={4}>
            <CurrencyLogo
              logoURI={market.iconUrl}
              boxSize={{ base: '60px', md: '80px' }}
              borderRadius="full"
              flexShrink={0}
            />

            <VStack align="flex-start" spacing={2} fontSize="sm">
              <HStack
                justify="space-between"
                w="full"
                flexWrap="wrap"
                spacing={2}
              >
                <Text fontWeight="bold">{market.baseTokenSymbol}</Text>
                <Text textColor="textSecondary">{market.baseTokenName}</Text>
                <Text textColor="textSecondary">
                  created{' '}
                  {formatShortDistanceToNow(new Date(market.marketCreatedAt))}{' '}
                  ago
                </Text>
              </HStack>

              <HStack>
                <Text>
                  <Box as="span" textColor="textSecondary">
                    MCAP
                  </Box>{' '}
                  {formatNumberNoDecimalsToK(market.baseMarketCapUsd, {
                    usd: true
                  })}
                </Text>

                <Text textColor="textSecondary">•</Text>

                <Text>
                  <Box as="span" textColor="textSecondary">
                    LIQ
                  </Box>{' '}
                  {formatNumberNoDecimalsToK(market.quoteLiquidityUsd, {
                    usd: true
                  })}
                </Text>

                <Text textColor="textSecondary">•</Text>

                <Text>
                  <Box as="span" textColor="textSecondary">
                    VOL
                  </Box>{' '}
                  {formatNumberNoDecimalsToK(market.volumeUsd, {
                    usd: true
                  })}
                </Text>
              </HStack>

              <HStack spacing={2}>
                <Text fontSize="sm" color="textSecondary">
                  TXNS
                </Text>
                <HStack justifyContent="flex-end" fontSize="sm">
                  <Text textColor="green.400">{market.numberBids}</Text>
                  <Text textColor="textSecondary">/</Text>
                  <Text textColor="red.500">{market.numberAsks}</Text>
                </HStack>
              </HStack>
            </VStack>
          </HStack>
        </Link>
      ) : (
        <VStack spacing={4}>
          <Image src={CrownIcon} w="100px" />
          <VStack spacing={0} fontSize="sm">
            <Text>The crown awaits a new king.</Text>
            <Text>
              <Link
                as={NavLink}
                fontWeight="bold"
                textColor="accent.500"
                to="/new"
              >
                Claim the crown
              </Link>{' '}
              and become King of the Mill.
            </Text>
          </VStack>
        </VStack>
      )}

      {chain === 'solana' && (
        <Link as={NavLink} to="/solana/crown-rush">
          <Button variant="boxShadowFlat" size="sm" bg="bgSecondary">
            View Leaderboard
          </Button>
        </Link>
      )}
    </VStack>
  )
}

export default KingOfTheMillSection
