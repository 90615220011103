import { useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { Chain, CrownRushLeaderboardEntry } from 'types/dexbarn'

interface UseGetCrownRushLeaderboardProps {
  chain: Chain
  fromTimestamp: number
  toTimestamp: number
  enabled?: boolean
}

const useGetCrownRushLeaderboard = ({
  chain,
  enabled = true,
  fromTimestamp,
  toTimestamp
}: UseGetCrownRushLeaderboardProps) => {
  const fetchLeaderboard = useSolbarnGet<CrownRushLeaderboardEntry[]>(
    `/v2/markets/${chain}/featured/leaderboard`
  )

  return useQuery<CrownRushLeaderboardEntry[]>({
    enabled,
    queryFn: () =>
      fetchLeaderboard({
        params: {
          fromTimestamp,
          toTimestamp
        }
      }),
    queryKey: ['GetCrownRushLeaderboard', fromTimestamp, toTimestamp]
  })
}

export default useGetCrownRushLeaderboard
