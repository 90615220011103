import { Box, Link, Text, useColorMode } from '@chakra-ui/react'
import {
  Bar,
  ChartingLibraryWidgetOptions,
  DatafeedConfiguration,
  LibrarySymbolInfo,
  ResolutionString,
  SubscribeBarsCallback,
  widget
} from 'charting_library'
import { IS_TESTNET } from 'constants/chains'
import { SOLBARN_TESTNET_WSS_URL, SOLBARN_WSS_URL } from 'constants/dexbarn'
import React, { useCallback, useRef, useState } from 'react'
import { Chain } from 'types/dexbarn'
import { Token } from 'types/token'
import { getChainId, getDexbarnChainParam } from 'utils/chains'
import { formattedNum } from 'utils/format'

import {
  convertResolutionToPeriod,
  getTokenMillCandlestickData,
  Resolution
} from './candles'
import { getAddress } from './helpers'

interface TradingViewChartProps {
  chain: Chain
  inputCurrency: Token
  outputCurrency: Token
  tmMarketAddress: string
  defaultResolution?: Resolution
  supportedResolutions?: Resolution[]
}

const TradingViewChart = ({
  chain,
  defaultResolution,
  inputCurrency,
  outputCurrency,
  supportedResolutions,
  tmMarketAddress
}: TradingViewChartProps) => {
  const chainId = getChainId(chain)
  const chartContainerRef = useRef<HTMLDivElement | null>(null)
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'

  const [baseCurrency, setBaseCurrency] = useState(inputCurrency)
  const [quoteCurrency, setQuoteCurrency] = useState(outputCurrency)

  const createDatafeed = useCallback(() => {
    const subscriptions: Record<string, WebSocket> = {}

    const configurationData: DatafeedConfiguration = {
      supported_resolutions: supportedResolutions as ResolutionString[]
    }

    return {
      getBars: async (
        symbolInfo: any,
        resolution: any,
        periodParams: any,
        onHistoryCallback: any,
        onErrorCallback: any
      ) => {
        const { from, to } = periodParams

        try {
          const data = await getTokenMillCandlestickData({
            chainId,
            from,
            marketAddress: tmMarketAddress,
            resolution,
            to
          })

          if (!data.length) {
            onHistoryCallback([], { noData: true })
            return
          }

          const baseTokenAddress = data[0].baseAsset
          const quoteTokenAddress = data[0].quoteAsset

          const newBaseCurrency =
            getAddress(chainId, inputCurrency)?.toLowerCase() ===
            baseTokenAddress?.toLowerCase()
              ? inputCurrency
              : outputCurrency

          const newQuoteCurrency =
            getAddress(chainId, inputCurrency)?.toLowerCase() ===
            quoteTokenAddress?.toLowerCase()
              ? inputCurrency
              : outputCurrency

          setBaseCurrency(newBaseCurrency)
          setQuoteCurrency(newQuoteCurrency)

          const bars: Bar[] = data.map((bar) => ({
            close: bar.close,
            high: bar.high,
            low: bar.low,
            open: bar.open,
            time: bar.timestamp * 1000
          }))

          onHistoryCallback(bars, { noData: false })
        } catch (error) {
          console.error('[getBars]: Get error', error)
          onErrorCallback(error)
        }
      },
      onReady: (callback: any) => {
        setTimeout(() => callback(configurationData), 0)
      },
      resolveSymbol: (symbolName: string, onSymbolResolvedCallback: any) => {
        const symbolInfo = {
          has_daily: true,
          has_intraday: true,
          has_no_volume: false,
          minmov: 0.000001,
          name: symbolName,
          pricescale: 100000,
          session: '24x7',
          supported_resolutions: configurationData.supported_resolutions,
          ticker: symbolName,
          timezone: 'Etc/UTC'
        }

        onSymbolResolvedCallback(symbolInfo)
      },
      searchSymbols: () => {},
      subscribeBars: (
        symbolInfo: LibrarySymbolInfo,
        resolution: any,
        onTick: SubscribeBarsCallback,
        listenerGuid: string
      ) => {
        if (!tmMarketAddress) return

        const baseUrl = IS_TESTNET ? SOLBARN_TESTNET_WSS_URL : SOLBARN_WSS_URL
        const period = convertResolutionToPeriod(resolution)
        const chainParam = getDexbarnChainParam(chainId)
        const ws = new WebSocket(
          `${baseUrl}/v2/candles/ws/${chainParam}/${tmMarketAddress}/${period}`
        )

        ws.onmessage = (event: MessageEvent) => {
          const message = JSON.parse(event.data)

          onTick({
            close: Number(message.close),
            high: Number(message.high),
            low: Number(message.low),
            open: Number(message.open),
            time: message.timestamp * 1000
          })
        }

        subscriptions[listenerGuid] = ws
      },
      unsubscribeBars: (listenerGuid: string) => {
        if (subscriptions[listenerGuid]) {
          subscriptions[listenerGuid].close()
          delete subscriptions[listenerGuid]
        }
      }
    }
  }, [
    chainId,
    inputCurrency,
    outputCurrency,
    tmMarketAddress,
    supportedResolutions
  ])

  const initializeChart = useCallback(() => {
    if (chartContainerRef.current === null) return

    const widgetOptions: ChartingLibraryWidgetOptions = {
      autosize: true,
      charts_storage_api_version: '1.1',
      charts_storage_url: 'https://saveload.tradingview.com',
      client_id: 'traderjoexyz.com',
      container: chartContainerRef.current,
      custom_formatters: {
        priceFormatterFactory: () => {
          return {
            format: (price: any) => {
              return formattedNum(price, { allowSmallDecimals: true })
            }
          }
        }
      } as any,
      datafeed: createDatafeed(),
      debug: process.env.NODE_ENV === 'development',
      disabled_features: [
        'use_localstorage_for_settings',
        'popup_hints',
        'symbol_search_hot_key',
        'header_symbol_search',
        'header_compare',
        'chart_template_storage',
        'header_saveload'
      ],
      fullscreen: false,
      interval: defaultResolution as ResolutionString,
      library_path: '/charting_library/',
      locale: 'en',
      studies_overrides: {},
      symbol: `${baseCurrency.symbol}/${quoteCurrency.symbol}`,
      theme: isDark ? 'dark' : 'light'
    }

    const tvWidget = new widget(widgetOptions)

    return () => {
      tvWidget.remove()
    }
  }, [baseCurrency, quoteCurrency, createDatafeed, defaultResolution, isDark])

  React.useLayoutEffect(() => {
    const cleanup = initializeChart()
    return cleanup
  }, [initializeChart])

  return (
    <Box w="full">
      <Box
        ref={chartContainerRef}
        className="TradingViewChart"
        width="full"
        h="50svh"
        maxH={{ base: '400px', md: '600px' }}
      />
      <Text fontSize="xs" mt={2} textColor="textSecondary">
        Chart powered by{' '}
        <Link
          href="https://tradingview.com"
          isExternal
          fontWeight="bold"
          textColor="textPrimary"
        >
          TradingView
        </Link>
      </Text>
    </Box>
  )
}

export default TradingViewChart
