import { CNATIVE } from 'constants/token'
import useGetMarket from 'hooks/barn/useGetMarket'
import { UseGetMarketProps } from 'hooks/tokenmill/useGetMarket'
import { useMemo } from 'react'
import { getCurrentPrices, PricePoint } from 'utils/bondingCurves'
import { getChainId, getChainType } from 'utils/chains'
import { getResizedImageUrl } from 'utils/image'
import { formatUnits } from 'viem'

import useGetMarketStakingData from './useGetMarketStakingData'
import useMarketData from './useMarketData'

const useGetMarketSolana = ({
  chain,
  filterBy,
  marketAddress
}: UseGetMarketProps) => {
  const chainId = getChainId(chain)
  const chainType = getChainType(chain)

  const { data: dexbarnMarket, isLoading: isLoadingDexbarnMarket } =
    useGetMarket({
      chain,
      enabled: chainType === 'solana',
      filterBy,
      marketAddress
    })

  const {
    data: marketData,
    isLoading: isLoadingMarketData,
    refetch: refetchMarketData
  } = useMarketData(marketAddress)

  const { data: marketStakingData } = useGetMarketStakingData({
    baseTokenAddress: marketData?.baseTokenMint.toBase58(),
    chain,
    enabled: !!marketData,
    marketAddress
  })

  const market = useMemo(() => {
    if (!dexbarnMarket || !marketData) return undefined

    const isMarketAvailableOnBarn =
      dexbarnMarket.baseToken.address !== 'dummy-base-token'
    const baseTokenDecimals = isMarketAvailableOnBarn
      ? dexbarnMarket.baseToken.decimals
      : 6

    const circulatingSupply = dexbarnMarket.circulatingSupply
    const circulatingSupplyUsd = dexbarnMarket?.baseMarketCapUsd

    const totalSupply = Number(
      formatUnits(marketData.totalSupply, baseTokenDecimals)
    )

    const numPoints = marketData.askPrices.length
    const askPricesPoints: PricePoint[] = marketData.askPrices.map(
      (price, i) => ({
        price: Number(formatUnits(price, 10)),
        supply: (totalSupply / (numPoints - 1)) * i
      })
    )
    const bidPricesPoints: PricePoint[] = marketData.bidPrices.map(
      (price, i) => ({
        price: Number(formatUnits(price, 10)),
        supply: (totalSupply / (numPoints - 1)) * i
      })
    )

    const { askPrice, bidPrice } = getCurrentPrices(
      askPricesPoints,
      bidPricesPoints,
      circulatingSupply +
        Number(marketStakingData?.totalLockedAmount?.formatted || '0')
    )

    return {
      askPrice,
      askPricesPoints,
      baseToken: {
        address: marketData.baseTokenMint.toBase58(),
        decimals: baseTokenDecimals,
        name: isMarketAvailableOnBarn
          ? dexbarnMarket.baseToken.name
          : marketData.baseTokenMetadata?.name || '',
        symbol: isMarketAvailableOnBarn
          ? dexbarnMarket.baseToken.symbol
          : marketData.baseTokenMetadata?.symbol || ''
      },
      baseTokenLogoUrl: getResizedImageUrl(dexbarnMarket.iconUrl) || '',
      baseTokenPctChange1d: dexbarnMarket.baseTokenPctChange1d,
      baseTokenPctChange1h: dexbarnMarket.baseTokenPctChange1h,
      baseTokenPctChange5m: dexbarnMarket.baseTokenPctChange5m,
      baseTokenPctChange6h: dexbarnMarket.baseTokenPctChange6h,
      baseTokenPriceNative: dexbarnMarket.baseToken.priceNative,
      baseTokenPriceUsd: dexbarnMarket.baseToken.priceUsd,
      bidPrice,
      bidPricesPoints,
      chain,
      circulatingSupply,
      createdAt: dexbarnMarket.marketCreatedAt,
      creator: marketData.creator.toBase58(),
      description: dexbarnMarket.description,
      discordUrl: dexbarnMarket.discordUrl,
      fees: marketData.fees,
      liquidityUsd: dexbarnMarket.quoteLiquidityUsd,
      marketAddress,
      marketCapUsd: circulatingSupplyUsd,
      nbBuyers: dexbarnMarket.numberBuyers,
      nbBuys: dexbarnMarket.numberBids,
      nbSellers: dexbarnMarket.numberSellers,
      nbSells: dexbarnMarket.numberAsks,
      nbSwaps: dexbarnMarket.numberSwaps,
      quoteCurrency: {
        address: marketData.quoteTokenMint.toBase58(),
        decimals: marketData.quoteTokenDecimals,
        name: CNATIVE[chainId].name,
        symbol: CNATIVE[chainId].symbol
      },
      quoteTokenPriceUsd: dexbarnMarket.quoteToken.priceUsd,
      stakingApr: dexbarnMarket.stakingApr,
      stakingFeesUsd:
        (dexbarnMarket.stakingShare / 10000) * dexbarnMarket.swapFeesUsd,
      swapFeesUsd: dexbarnMarket.swapFeesUsd,
      telegramUrl: dexbarnMarket.telegramUrl,
      totalSupply,
      twitterUrl: dexbarnMarket.twitterUrl,
      volumeUsd: dexbarnMarket.volumeUsd,
      websiteUrl: dexbarnMarket.websiteUrl
    }
  }, [
    chainId,
    chain,
    marketAddress,
    dexbarnMarket,
    marketData,
    marketStakingData
  ])

  return {
    data: market,
    isLoading: isLoadingDexbarnMarket || isLoadingMarketData,
    refetch: () => {
      refetchMarketData()
    }
  }
}

export default useGetMarketSolana
