import axios from 'axios'
import { IS_TESTNET, TokenMillChainId } from 'constants/chains'
import { SOLBARN_TESTNET_URL, SOLBARN_URL } from 'constants/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

type DexbarnCandle = {
  baseAsset: string
  close: number
  high: number
  low: number
  open: number
  quoteAsset: string
  timestamp: number
}

export type Resolution = '1' | '5' | '15' | '60' | '240' | '720' | '1D' | '1W'

const fetchTokenMillCandlestickData = async (
  marketAddress: string,
  period: number,
  from: number,
  to: number,
  chainId: TokenMillChainId
) => {
  try {
    const response = await axios.get<DexbarnCandle[]>(
      `${
        IS_TESTNET ? SOLBARN_TESTNET_URL : SOLBARN_URL
      }/v2/candles/${getDexbarnChainParam(chainId)}/${marketAddress}`,
      {
        params: {
          fromTimestamp: from,
          period,
          toTimestamp: to
        }
      }
    )
    return response.data
  } catch (error) {
    console.error('Error fetching candlestick data', error)
    return []
  }
}

interface GetTokenMillCandlestickDataProps {
  chainId: TokenMillChainId
  from: number
  marketAddress: string
  resolution: Resolution
  to: number
}

export const getTokenMillCandlestickData = async ({
  chainId,
  from,
  marketAddress,
  resolution,
  to
}: GetTokenMillCandlestickDataProps) => {
  const period = convertResolutionToPeriod(resolution)
  const data = await fetchTokenMillCandlestickData(
    marketAddress,
    period,
    from,
    to,
    chainId
  )

  if (data.length === 0) {
    return [] // Return empty array if no data is fetched
  }

  // Sort the data by timestamp to ensure it's in chronological order
  data.sort((a, b) => a.timestamp - b.timestamp)

  const firstCandleTimestamp = data[0].timestamp

  // Create an array of expected timestamps from the first candle to 'to'
  const expectedTimestamps: number[] = []
  for (
    let timestamp = firstCandleTimestamp;
    timestamp <= to;
    timestamp += period
  ) {
    expectedTimestamps.push(timestamp)
  }

  // Create a map of existing candles
  const candleMap = new Map(data.map((candle) => [candle.timestamp, candle]))

  // Fill in missing candles with forward-filled values
  const filledCandles: DexbarnCandle[] = []
  let lastCandle: DexbarnCandle = data[0] // Initialize with the first candle

  for (const timestamp of expectedTimestamps) {
    if (candleMap.has(timestamp)) {
      lastCandle = candleMap.get(timestamp)!
      filledCandles.push(lastCandle)
    } else {
      // For all missing timestamps, use the last known candle values
      filledCandles.push({
        ...lastCandle,
        close: lastCandle.close,
        high: lastCandle.close,
        low: lastCandle.close,
        open: lastCandle.close,
        timestamp
      })
    }
  }

  return filledCandles
}

export const convertResolutionToPeriod = (resolution: Resolution): number => {
  switch (resolution) {
    case '1':
      return 60
    case '5':
      return 300
    case '15':
      return 900
    case '60':
      return 3600
    case '240':
      return 14400
    case '720':
      return 43200
    case '1D':
      return 86400
    case '1W':
      return 604800
  }
}
