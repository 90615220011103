import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  Grid,
  Heading,
  Hide,
  HStack,
  Input,
  Link,
  Select,
  Show,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react'
import CopyableError from 'components/CopyableError'
import LoginModal from 'components/LoginModal'
import Popup from 'components/Popup'
import Warning from 'components/Warning'
import Web3Button from 'components/Web3Button'
import { IS_MONAD_ENABLED } from 'constants/chains'
import { DEFAULT_ASK_PRICES, DEFAULT_BID_PRICES } from 'constants/curves'
import { CNATIVE } from 'constants/token'
import usePatchMarket from 'hooks/barn/usePatchMarket'
import { useS3ImageUpload } from 'hooks/barn/useS3ImageUpload'
import useCreateMarketAndToken, {
  VestingArgs
} from 'hooks/tokenmill/useCreateMarketAndToken'
import { useCreateTokenLogoUploadUrl } from 'hooks/tokenmill/useCreateTokenLogoUploadUrl'
import useAddErrorPopup from 'hooks/useAddErrorPopup'
import React, { useCallback, useState } from 'react'
import { useGetAuthTokens, useIsLoggedIn } from 'state/authentication/hooks'
import { AuthTokens, Chain } from 'types/dexbarn'
import { shortenAddress } from 'utils/addresses'
import { PricePoint } from 'utils/bondingCurves'
import { getChainId } from 'utils/chains'
import { BaseError } from 'viem'

import CreateTokenHeader from './CreateTokenHeader'
import CreateTokenSocialUrlInputs from './CreateTokenSocialUrlInputs'
import ImagePicker from './ImagePicker'
import InitialPurchaseInput from './InitialPurchaseInput'
import TokenCreationStatusModal from './TokenCreationStatusModal'

const DEFAULT_VALUES = {
  creatorFeeShare: 0,
  decimals: 18,
  stakingFeeShare: 80,
  totalSupply: 1000000000
}

export type CreateTokenStatus =
  | 'idle'
  | 'create_market'
  | 'patch_market'
  | 'done'
  | 'error_create_market'
  | 'error_patch_market'

export type VestingArgsError = 'insufficient_balance' | 'no_amount' | 'none'

const CreateToken = () => {
  const toast = useToast()
  const addErrorPopup = useAddErrorPopup()

  // state
  const [selectedChain, setSelectedChain] = useState<Chain>(
    IS_MONAD_ENABLED ? 'monad' : 'solana'
  )
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [name, setName] = useState<string>('')
  const [isNameValid, setIsNameValid] = useState<boolean>(true)
  const [symbol, setSymbol] = useState<string>('')
  const [isSymbolValid, setIsSymbolValid] = useState<boolean>(true)
  const [description, setDescription] = useState<string>('')
  const [askPricePoints, setAskPricePoints] = useState<PricePoint[]>(
    DEFAULT_ASK_PRICES[selectedChain]
  )
  const [bidPricePoints, setBidPricePoints] = useState<PricePoint[]>(
    DEFAULT_BID_PRICES[selectedChain]
  )
  const [socialUrls, setSocialUrls] = useState({
    discord: '',
    telegram: '',
    twitter: '',
    website: ''
  })
  const [uploadedIconUrl, setUploadedIconUrl] = useState<string | null>(null)
  const [vestingArgs, setVestingArgs] = useState<VestingArgs | undefined>()
  const [vestingArgsError, setVestingArgsError] =
    useState<VestingArgsError>('none')
  const [creationStatus, setCreationStatus] =
    useState<CreateTokenStatus>('idle')
  const [hint, setHint] = useState<string>('')
  const [createdMarketAddress, setCreatedMarketAddress] = useState<string>('')

  const chainId = getChainId(selectedChain)
  const nativeCurrency = CNATIVE[chainId]

  // create market and token
  const { createMarketAndTokenAsync, error: createMarketSimulateError } =
    useCreateMarketAndToken({
      askPrices: askPricePoints.map((point) => point.price),
      bidPrices: bidPricePoints.map((point) => point.price),
      chain: selectedChain,
      creatorFeeShareBps: Math.round(DEFAULT_VALUES.creatorFeeShare * 100),
      decimals: DEFAULT_VALUES.decimals,
      enabled: vestingArgsError === 'none',
      name,
      stakingFeeShareBps: Math.round(DEFAULT_VALUES.stakingFeeShare * 100),
      symbol,
      totalSupply: DEFAULT_VALUES.totalSupply,
      vestingArgs
    })

  // token logo upload
  const createTokenLogoUploadUrl = useCreateTokenLogoUploadUrl({
    chain: selectedChain
  })
  const uploadImage = useS3ImageUpload()

  // create token click handler
  const {
    isOpen: isLoginModalOpen,
    onClose: onLoginModalClose,
    onOpen: onLoginModalOpen
  } = useDisclosure()
  const { mutateAsync: patchMarket } = usePatchMarket({ chain: selectedChain })
  const isLoggedIn = useIsLoggedIn(selectedChain)
  const { tokens: authTokens } = useGetAuthTokens(selectedChain)
  const createToken = useCallback(
    async (authTokens: AuthTokens | undefined) => {
      if (vestingArgsError !== 'none' || !createMarketAndTokenAsync) {
        return
      }

      if (!isLoggedIn || !authTokens) {
        onLoginModalOpen()
        return
      }

      async function runPatchMatch(marketAddress: string, iconUrl: string) {
        if (!authTokens) {
          return
        }

        setCreationStatus('patch_market')
        await patchMarket({
          args: {
            description,
            discordUrl: socialUrls.discord,
            iconUrl: iconUrl,
            telegramUrl: socialUrls.telegram,
            twitterUrl: socialUrls.twitter,
            websiteUrl: socialUrls.website
          },
          authTokens,
          chain: selectedChain,
          marketAddress
        })
      }

      try {
        // If we're retrying after a market patch error, jump straight to that step
        if (creationStatus === 'error_patch_market' && uploadedIconUrl) {
          await runPatchMatch(createdMarketAddress, uploadedIconUrl)
          setCreationStatus('done')
          return
        }

        // Create market
        setCreationStatus('create_market')
        if (!selectedImage) throw new Error('No image selected')

        // upload logo
        const { destinationUrl, fields, presignedUploadUrl } =
          await createTokenLogoUploadUrl({
            authTokens,
            contentType: selectedImage.type
          })
        await uploadImage(presignedUploadUrl, selectedImage, fields)
        const iconUrl = destinationUrl
        setUploadedIconUrl(iconUrl)

        if (!iconUrl) {
          throw new Error('Unable to upload logo')
        }

        // Create market and token
        const { hash, marketAddress } = await createMarketAndTokenAsync({
          logoURI: iconUrl
        })

        // Save market and base token addresses
        setCreatedMarketAddress(marketAddress)

        // PATCH market
        await runPatchMatch(marketAddress, iconUrl)

        // Update toast
        toast.update(hash, {
          duration: null,
          render: (props) => (
            <Popup
              externalLink={{
                name: 'View Token',
                url: `/${selectedChain}/${marketAddress}`
              }}
              summary="Created token"
              {...props}
            />
          ),
          status: 'success'
        })

        setCreationStatus('done')
      } catch (err) {
        if (err instanceof Error) {
          if (
            err.name !== 'UserRejectedRequestError' &&
            !err.message.includes('User rejected the request')
          ) {
            addErrorPopup({
              subtitle: 'Unable to create token',
              summary: err.message
            })
          }
        }
        setCreationStatus((curr) =>
          curr === 'patch_market' ? 'error_patch_market' : 'error_create_market'
        )
        throw err
      }
    },
    [
      createMarketAndTokenAsync,
      createTokenLogoUploadUrl,
      description,
      isLoggedIn,
      vestingArgsError,
      patchMarket,
      selectedImage,
      socialUrls,
      uploadImage,
      addErrorPopup,
      toast,
      onLoginModalOpen,
      selectedChain,
      creationStatus,
      createdMarketAddress,
      uploadedIconUrl
    ]
  )

  const handleChainChange = (value: Chain) => {
    setSelectedChain(value)
    setAskPricePoints(DEFAULT_ASK_PRICES[value])
    setBidPricePoints(DEFAULT_BID_PRICES[value])
  }

  const handleCreateTokenClick = () => {
    if (!name) {
      setHint('Name is required')
    } else if (!symbol) {
      setHint('Symbol is required')
    } else if (!selectedImage) {
      setHint('Token logo is required')
    } else if (vestingArgsError === 'no_amount') {
      setHint('Buy amount is required')
    } else if (vestingArgsError === 'insufficient_balance') {
      setHint(`You don't have enough ${nativeCurrency.symbol}`)
    } else if (vestingArgsError === 'none' && !!createMarketAndTokenAsync) {
      setHint('')
      createToken(authTokens)
    }
  }

  return (
    <Box maxW="1600px" margin="0 auto">
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={onLoginModalClose}
        onLoginSuccess={(tokens) => createToken(tokens)}
        chain={selectedChain}
        type="token"
      />

      <TokenCreationStatusModal
        createTokenStatus={creationStatus}
        marketAddress={createdMarketAddress}
        chain={selectedChain}
        onRetry={() => createToken(authTokens)}
        baseTokenSymbol={symbol}
      />

      <Box maxW="700px" margin="0 auto">
        <CreateTokenHeader />

        <Divider />

        <VStack p={{ base: 4, md: 6 }} spacing={4} align="flex-start">
          <Heading size="md">Token Info</Heading>

          <Grid w="full" gap={4} templateColumns="160px auto">
            <ImagePicker
              selectedImage={selectedImage}
              setSelectedImage={(file) => {
                setSelectedImage(file)
                setHint('')
              }}
            />

            <SimpleGrid columns={1} gap={4} w="full">
              <VStack align="flex-start">
                <HStack w="full" justify="space-between">
                  <Text textColor="textPrimary" fontSize="sm">
                    Name
                  </Text>
                  <Text
                    textColor={isNameValid ? 'textSecondary' : 'red.500'}
                    fontSize="sm"
                  >
                    {name.length}/32
                  </Text>
                </HStack>
                <Input
                  placeholder="Enter Name"
                  value={name}
                  isInvalid={!isNameValid}
                  onChange={(e) => {
                    const val = e.currentTarget.value
                    setIsNameValid(val.length > 0 && val.length <= 32)
                    setName(val)
                    setHint('')
                  }}
                />
              </VStack>

              <VStack align="flex-start">
                <HStack w="full" justify="space-between">
                  <Text textColor="textPrimary" fontSize="sm">
                    Symbol
                  </Text>
                  <Text
                    textColor={isSymbolValid ? 'textSecondary' : 'red.500'}
                    fontSize="sm"
                  >
                    {symbol.length}/8
                  </Text>
                </HStack>
                <Input
                  placeholder="Enter Symbol"
                  value={symbol}
                  isInvalid={!isSymbolValid}
                  onChange={(e) => {
                    const val = e.currentTarget.value
                    setIsSymbolValid(val.length > 0 && val.length <= 8)
                    setSymbol(val)
                    setHint('')
                  }}
                />
              </VStack>
            </SimpleGrid>
          </Grid>

          <VStack align="flex-start" w="full">
            <Text textColor="textPrimary" fontSize="sm">
              Chain
            </Text>

            <Select
              bg="bgSecondary"
              borderRadius={0}
              border={0}
              value={selectedChain}
              onChange={(e) => handleChainChange(e.target.value as Chain)}
            >
              {IS_MONAD_ENABLED ? (
                <option value="monad">Monad</option>
              ) : (
                <>
                  <option value="avalanche">Avalanche</option>
                  <option value="solana">Solana</option>
                </>
              )}
            </Select>
          </VStack>

          <VStack align="flex-start" w="full">
            <Text textColor="textPrimary" fontSize="sm">
              Description
            </Text>
            <Input
              placeholder="PUMP IT"
              value={description}
              onChange={(e) => setDescription(e.currentTarget.value)}
              borderColor={isSymbolValid ? 'border' : 'red.500'}
            />
          </VStack>

          <CreateTokenSocialUrlInputs
            socialUrls={socialUrls}
            setSocialUrls={setSocialUrls}
            isExpandable={true}
          />
        </VStack>

        <Divider />

        <Hide below="md">
          <InitialPurchaseInput
            baseTokenSymbol={symbol}
            totalSupply={DEFAULT_VALUES.totalSupply}
            chain={selectedChain}
            quoteToken={nativeCurrency}
            vestingArgs={vestingArgs}
            setVestingArgs={setVestingArgs}
            setVestingArgsError={setVestingArgsError}
          />

          <Divider />

          <VStack p={{ base: 4, md: 6 }} spacing={4}>
            <Web3Button
              chain={selectedChain}
              variant="boxShadowFlat"
              bg="accent.500"
              w="full"
              size="lg"
              isLoading={
                creationStatus === 'create_market' ||
                creationStatus === 'patch_market'
              }
              loadingText={
                creationStatus === 'patch_market'
                  ? 'Waiting for token to be available on Token Mill'
                  : 'Creating Token'
              }
              onClick={handleCreateTokenClick}
            >
              {creationStatus === 'create_market' ||
              creationStatus === 'patch_market'
                ? 'Retry'
                : 'Create Token'}
            </Web3Button>

            {createMarketSimulateError ? (
              <CopyableError
                error={createMarketSimulateError?.message}
                summary={(createMarketSimulateError as BaseError).shortMessage}
              />
            ) : null}

            {hint ? <Warning text={hint} /> : null}

            {createdMarketAddress && creationStatus === 'done' ? (
              <VStack
                p={4}
                w="full"
                bg="bgSecondary"
                align="flex-start"
                fontSize="sm"
              >
                <Text>
                  Token Created {shortenAddress(createdMarketAddress)}
                </Text>
                <Link
                  href={`/${selectedChain}/${createdMarketAddress}`}
                  isExternal
                  color="accent.500"
                >
                  <HStack w="fit-content">
                    <Text textColor="accent.500">View Token</Text>
                    <ExternalLinkIcon />
                  </HStack>
                </Link>
              </VStack>
            ) : null}
          </VStack>
        </Hide>
      </Box>

      <Hide below="md">
        <Divider orientation="vertical" />
      </Hide>

      <Box>
        <VStack p={{ base: 4, md: 6 }} spacing={4} align="flex-start"></VStack>
      </Box>

      <Show below="md">
        <InitialPurchaseInput
          chain={selectedChain}
          totalSupply={DEFAULT_VALUES.totalSupply}
          baseTokenSymbol={symbol}
          quoteToken={nativeCurrency}
          vestingArgs={vestingArgs}
          setVestingArgs={setVestingArgs}
          setVestingArgsError={setVestingArgsError}
        />

        <Divider />

        <VStack p={{ base: 4, md: 6 }} spacing={4}>
          <Web3Button
            chain={selectedChain}
            variant="boxShadowFlat"
            bg="accent.500"
            w="full"
            size="lg"
            isLoading={
              creationStatus === 'create_market' ||
              creationStatus === 'patch_market'
            }
            loadingText={'Creating Token'}
            onClick={handleCreateTokenClick}
          >
            Create Token
          </Web3Button>

          {createMarketSimulateError ? (
            <CopyableError
              error={createMarketSimulateError?.message}
              summary={(createMarketSimulateError as BaseError).shortMessage}
            />
          ) : null}

          {hint ? <Warning text={hint} /> : null}

          {createdMarketAddress && creationStatus === 'done' ? (
            <VStack
              p={4}
              w="full"
              bg="bgSecondary"
              align="flex-start"
              fontSize="sm"
            >
              <Text>Token Created {shortenAddress(createdMarketAddress)}</Text>
              <Link
                href={`/${selectedChain}/${createdMarketAddress}`}
                isExternal
                color="accent.500"
              >
                <HStack w="fit-content">
                  <Text textColor="accent.500">View Token</Text>
                  <ExternalLinkIcon />
                </HStack>
              </Link>
            </VStack>
          ) : null}
        </VStack>
      </Show>
    </Box>
  )
}

export default CreateToken
