import { useInfiniteQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { ChainFilter, MarketData } from 'types/dexbarn'
import { Market } from 'types/market'
import { SolbarnMarketQueryParam } from 'types/solbarn'
import { getResizedImageUrl } from 'utils/image'

export interface UseGetMarketsParams {
  chainFilter: ChainFilter
  enabled?: boolean
  excludeLowVolumeMarkets?: boolean
  filterBy?: SolbarnMarketQueryParam.FilterBy
  graduated?: boolean
  isDesc?: boolean
  orderBy?: SolbarnMarketQueryParam.OrderBy
  pageSize?: number
}

const useGetMarkets = ({
  chainFilter,
  enabled = true,
  excludeLowVolumeMarkets,
  filterBy,
  graduated,
  isDesc,
  orderBy = 'new',
  pageSize = 20
}: UseGetMarketsParams) => {
  const fetchMarkets = useSolbarnGet<MarketData[]>('/v2/markets')
  const fetchGraduatedMarkets = useSolbarnGet<MarketData[]>(
    '/v2/markets/graduated'
  )

  return useInfiniteQuery<Market[]>({
    enabled,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      let markets: MarketData[] = []
      if (graduated) {
        markets = await fetchGraduatedMarkets({
          params: {
            chains: chainFilter,
            isDesc,
            orderBy,
            pageNum: pageParam,
            pageSize
          }
        })
      } else {
        markets = await fetchMarkets({
          params: {
            chains: chainFilter,
            excludeLowVolumeMarkets,
            filterBy,
            isDesc,
            orderBy,
            pageNum: pageParam,
            pageSize
          }
        })
      }

      return markets.map((market) => ({
        baseToken: market.baseToken,
        baseTokenPctChange1d: market.baseTokenPctChange1d,
        baseTokenPctChange1h: market.baseTokenPctChange1h,
        baseTokenPctChange5m: market.baseTokenPctChange5m,
        baseTokenPctChange6h: market.baseTokenPctChange6h,
        chain: market.chain,
        circulatingSupply: market.circulatingSupply,
        circulatingSupplyUsd: market.baseMarketCapUsd,
        createdAt: new Date(market.marketCreatedAt),
        description: market.description,
        graduatedAt: market.graduatedTimestamp
          ? new Date(market.graduatedTimestamp)
          : undefined,
        liquidityUsd: market.quoteLiquidityUsd,
        logoUrl: getResizedImageUrl(market.iconUrl) || '',
        marketAddress: market.marketAddress,
        numberBuys: market.numberBids,
        numberSells: market.numberAsks,
        quoteToken: {
          address: market.quoteToken.address,
          decimals: market.quoteToken.decimals,
          name: market.quoteToken.name,
          priceNative: market.quoteToken.priceNative,
          priceUsd: market.quoteToken.priceUsd,
          symbol: market.quoteToken.symbol
        },
        stakingApr: market.stakingApr,
        stakingPct: market.stakingPct,
        vestingPct: market.vestingPct,
        volumeUsd: market.volumeUsd
      }))
    },
    queryKey: [
      'GetMarkets',
      pageSize,
      orderBy,
      filterBy,
      excludeLowVolumeMarkets,
      isDesc,
      chainFilter,
      graduated
    ]
  })
}

export default useGetMarkets
