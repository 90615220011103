import { IS_TESTNET } from 'constants/chains'
import { SOLBARN_TESTNET_WSS_URL, SOLBARN_WSS_URL } from 'constants/dexbarn'
import { useEffect, useRef, useState } from 'react'
import { Chain, ChainFilter } from 'types/dexbarn'
import { Market } from 'types/market'
import { getResizedImageUrl } from 'utils/image'

interface WSMarketData {
  baseToken: {
    address: string
    decimals: number
    name: string
    priceNative: string
    priceUsd: number
    symbol: string
  }
  chain: Chain
  circulatingSupply: number
  circulatingSupplyUsd: number
  description: string | null
  iconUrl: string | null
  marketAddress: string
  marketCreatedAt: string
  quoteToken: {
    address: string
    decimals: number
    name: string
    priceNative: string
    priceUsd: number
    symbol: string
  }
  timestamp: number
}

interface UseLiveMarketsProps {
  chainFilter: ChainFilter
  isEnabled: boolean
  isPaused: boolean
}

const useLiveMarkets = ({
  chainFilter,
  isEnabled,
  isPaused
}: UseLiveMarketsProps) => {
  const [liveMarkets, setLiveMarkets] = useState<WSMarketData[]>([])
  const [lastPausedTimestamp, setLastPausedTimestamp] = useState<number | null>(
    null
  )
  const wsRef = useRef<WebSocket | null>(null)

  useEffect(() => {
    if (isPaused) {
      setLastPausedTimestamp(Date.now())
    } else {
      setLastPausedTimestamp(null)
    }
  }, [isPaused])

  useEffect(() => {
    if (!isEnabled) return

    const baseUrl = IS_TESTNET ? SOLBARN_TESTNET_WSS_URL : SOLBARN_WSS_URL
    wsRef.current = new WebSocket(`${baseUrl}/v2/markets/ws/${chainFilter}`)

    wsRef.current.onmessage = (event) => {
      const newMarket: WSMarketData = {
        ...JSON.parse(event.data),
        timestamp: Date.now()
      }

      setLiveMarkets((prevMarkets) => {
        const exists = prevMarkets.some(
          (market) => market.marketAddress === newMarket.marketAddress
        )
        if (exists) return prevMarkets
        return [newMarket, ...prevMarkets]
      })
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close()
      }
    }
  }, [isEnabled, chainFilter])

  const filteredMarkets = liveMarkets.filter((market) => {
    if (!lastPausedTimestamp) return true
    return market.timestamp <= lastPausedTimestamp
  })

  const markets: Market[] = filteredMarkets.map((market) => ({
    baseToken: market.baseToken,
    baseTokenPctChange1d: 0,
    baseTokenPctChange1h: 0,
    baseTokenPctChange5m: 0,
    baseTokenPctChange6h: 0,
    chain: market.chain,
    circulatingSupply: market.circulatingSupply,
    circulatingSupplyUsd: market.circulatingSupplyUsd,
    createdAt: new Date(market.marketCreatedAt),
    description: market.description || '',
    liquidityUsd: 0,
    logoUrl: getResizedImageUrl(market.iconUrl || '') || '',
    marketAddress: market.marketAddress,
    numberBuys: 0,
    numberSells: 0,
    quoteToken: market.quoteToken,
    stakingApr: 0,
    stakingPct: 0,
    vestingPct: 0,
    volumeUsd: 0
  }))

  const pausedMarketsCount = liveMarkets.filter((market) => {
    if (!lastPausedTimestamp) return false
    return market.timestamp > lastPausedTimestamp
  }).length

  return {
    data: markets,
    pausedMarketsCount
  }
}

export default useLiveMarkets
