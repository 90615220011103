import axios, { CreateAxiosDefaults } from 'axios'

export const SOLBARN_URL = process.env.REACT_APP_SOLBARN_URL
if (typeof SOLBARN_URL === 'undefined') {
  throw new Error(
    'REACT_APP_SOLBARN_URL must be a defined environment variable'
  )
}

export const SOLBARN_TESTNET_URL = process.env.REACT_APP_SOLBARN_TESTNET_URL
if (typeof SOLBARN_TESTNET_URL === 'undefined') {
  throw new Error(
    'REACT_APP_SOLBARN_TESTNET_URL must be a defined environment variable'
  )
}

export const SOLBARN_WSS_URL = process.env.REACT_APP_SOLBARN_WSS_URL
if (typeof SOLBARN_WSS_URL === 'undefined') {
  throw new Error(
    'REACT_APP_SOLBARN_WSS_URL must be a defined environment variable'
  )
}

export const SOLBARN_TESTNET_WSS_URL =
  process.env.REACT_APP_SOLBARN_TESTNET_WSS_URL
if (typeof SOLBARN_TESTNET_WSS_URL === 'undefined') {
  throw new Error(
    'REACT_APP_SOLBARN_TESTNET_WSS_URL must be a defined environment variable'
  )
}

const clientConfig: CreateAxiosDefaults = {
  headers: { accept: 'application/json' },
  withCredentials: true
}

export const solbarnClient = axios.create({
  ...clientConfig,
  baseURL: SOLBARN_URL
})

export const solbarnTestnetClient = axios.create({
  ...clientConfig,
  baseURL: SOLBARN_TESTNET_URL
})
