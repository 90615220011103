import {
  Box,
  Button,
  Center,
  Flex,
  Hide,
  HStack,
  Show,
  Spacer,
  Tab,
  TabList,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { IS_MONAD_ENABLED } from 'constants/chains'
import useGetMarkets from 'hooks/barn/useGetMarkets'
import useLiveMarkets from 'hooks/barn/useLiveMarkets'
import React, { useMemo, useState } from 'react'
import { ChainFilter } from 'types/dexbarn'
import { SolbarnMarketQueryParam } from 'types/solbarn'

import ChainMenu from './ChainMenu'
import HomeHeader from './HomeHeader'
import KingOfTheMillSection from './KingOfTheMillSection'
import LiveIndicator from './LiveIndicator'
import MarketCard from './MarketCard'
import MarketsSortMenu from './MarketsSortMenu'
import MarketsTable from './MarketsTable'

const filterOptions: SolbarnMarketQueryParam.FilterBy[] = [
  'm5',
  'h1',
  'h6',
  'd1'
]
const defaultFilterBy: SolbarnMarketQueryParam.FilterBy = 'd1'

export type SortState = {
  filterBy: SolbarnMarketQueryParam.FilterBy
  graduated: boolean
  isDescending: boolean
  method: SolbarnMarketQueryParam.OrderBy
}

const TokenMillDesktop = () => {
  const [sortState, setSortState] = useState<SortState>({
    filterBy: defaultFilterBy,
    graduated: false,
    isDescending: true,
    method: 'trending'
  })

  const [selectedChain, setSelectedChain] = useState<ChainFilter>(
    IS_MONAD_ENABLED ? 'monad' : 'all'
  )
  const [isPaused, setIsPaused] = useState<boolean>(false)
  const isLive = !isPaused && sortState.method === 'new'

  const {
    data: marketsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = useGetMarkets({
    chainFilter: selectedChain,
    excludeLowVolumeMarkets: false,
    filterBy: sortState.filterBy,
    graduated: sortState.graduated,
    isDesc: sortState.isDescending,
    orderBy: sortState.method
  })

  const { data: liveMarkets, pausedMarketsCount } = useLiveMarkets({
    chainFilter: selectedChain,
    isEnabled: true,
    isPaused: !isLive
  })

  const markets = useMemo(() => {
    const allMarkets = marketsData?.pages.flatMap((page) => page) || []
    return sortState.method === 'new'
      ? [...liveMarkets, ...allMarkets]
      : allMarkets
  }, [marketsData, liveMarkets, sortState.method])

  const onHeaderClick = (method: SolbarnMarketQueryParam.OrderBy) => {
    setSortState((prev) => ({
      ...prev,
      isDescending: method === prev.method ? !prev.isDescending : true,
      method: method === prev.method ? prev.method : method
    }))
  }

  let sortMethodTabIndex = -1
  if (sortState.method === 'new') {
    sortMethodTabIndex = 0
  } else if (sortState.method === 'trending') {
    sortMethodTabIndex = 1
  } else if (sortState.graduated) {
    sortMethodTabIndex = 2
  }

  return (
    <Box px={4}>
      <Center mt={{ base: 4, md: 6 }} mb={{ base: 4, md: 8 }}>
        <KingOfTheMillSection
          chain={selectedChain === 'all' ? 'avalanche' : selectedChain}
        />
      </Center>

      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        gap={{ base: 4, md: 8 }}
        w="full"
        alignItems="center"
      >
        <Tabs
          variant="bracketed"
          index={sortMethodTabIndex}
          overflowX={{ base: 'auto', md: 'visible' }}
          w={{ base: 'full', md: 'auto' }}
        >
          <TabList>
            <Tab
              onClick={() => {
                setSortState({
                  filterBy: 'm5',
                  graduated: false,
                  isDescending: true,
                  method: 'new'
                })
              }}
              whiteSpace="nowrap"
            >
              New
            </Tab>
            <Tab
              onClick={() => {
                setSortState({
                  filterBy: defaultFilterBy,
                  graduated: false,
                  isDescending: true,
                  method: 'trending'
                })
              }}
              whiteSpace="nowrap"
            >
              Trending
            </Tab>
            <Tab
              onClick={() => {
                setSortState({
                  filterBy: 'd1',
                  graduated: true,
                  isDescending: true,
                  method: 'marketCap'
                })
              }}
              whiteSpace="nowrap"
            >
              Graduated
            </Tab>
          </TabList>
        </Tabs>

        <Tabs
          variant="bracketed"
          index={filterOptions.indexOf(sortState.filterBy)}
          onChange={(index) =>
            setSortState((prev) => ({
              ...prev,
              filterBy: filterOptions[index]
            }))
          }
          overflowX={{ base: 'auto', md: 'visible' }}
          w={{ base: 'full', md: 'auto' }}
        >
          <TabList>
            {filterOptions.map((option) => (
              <Tab
                key={option}
                whiteSpace="nowrap"
                isDisabled={sortState.graduated}
              >
                {option.toUpperCase()}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Spacer />

        {sortState.method === 'new' ? (
          <LiveIndicator
            isPaused={!isLive}
            onTogglePause={() => setIsPaused(!isPaused)}
            pausedDataCount={pausedMarketsCount}
          />
        ) : null}

        <ChainMenu currentChain={selectedChain} onChange={setSelectedChain} />
      </Flex>

      <MarketsTable
        graduated={sortState.graduated}
        markets={markets}
        sortMethod={sortState.method}
        isSortDescending={sortState.isDescending}
        onHeaderClick={onHeaderClick}
      />

      {hasNextPage && !isLoading && (
        <Center mt={4} w="full">
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            color="textPrimary"
            isLoading={isFetchingNextPage}
            onClick={() => fetchNextPage()}
          >
            Load More
          </Button>
        </Center>
      )}
    </Box>
  )
}

const TokenMillMobile = () => {
  const [sortState, setSortState] = useState<SortState>({
    filterBy: defaultFilterBy,
    graduated: false,
    isDescending: true,
    method: 'trending'
  })

  const [selectedChain, setSelectedChain] = useState<ChainFilter>(
    IS_MONAD_ENABLED ? 'monad' : 'all'
  )
  const [isPaused, setIsPaused] = useState<boolean>(false)
  const isLive = !isPaused && sortState.method === 'new'

  const {
    data: marketsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = useGetMarkets({
    chainFilter: selectedChain,
    excludeLowVolumeMarkets: false,
    filterBy: sortState.filterBy,
    graduated: sortState.graduated,
    isDesc: sortState.isDescending,
    orderBy: sortState.method
  })

  const { data: liveMarkets, pausedMarketsCount } = useLiveMarkets({
    chainFilter: selectedChain,
    isEnabled: true,
    isPaused: !isLive
  })

  const markets = useMemo(() => {
    const allMarkets = marketsData?.pages.flatMap((page) => page) || []
    return sortState.method === 'new'
      ? [...liveMarkets, ...allMarkets]
      : sortState.method === 'marketCap'
        ? allMarkets.sort((a, b) =>
            sortState.isDescending
              ? b.circulatingSupplyUsd - a.circulatingSupplyUsd
              : a.circulatingSupplyUsd - b.circulatingSupplyUsd
          )
        : allMarkets
  }, [marketsData, liveMarkets, sortState.method, sortState.isDescending])

  let sortMethodTabIndex = -1
  if (sortState.method === 'new') {
    sortMethodTabIndex = 0
  } else if (sortState.method === 'trending') {
    sortMethodTabIndex = 1
  } else if (sortState.graduated) {
    sortMethodTabIndex = 2
  }

  return (
    <VStack align="stretch" px={4} spacing={4} w="full">
      <Center mt={{ base: 4, md: 6 }} mb={{ base: 4, md: 8 }}>
        <KingOfTheMillSection
          chain={selectedChain === 'all' ? 'avalanche' : selectedChain}
        />
      </Center>

      <Flex flexDir="column" gap={4} w="full" alignItems="flex-start">
        <Tabs variant="bracketed" index={sortMethodTabIndex} w="full" isFitted>
          <TabList>
            <Tab
              onClick={() => {
                setSortState({
                  filterBy: 'm5',
                  graduated: false,
                  isDescending: true,
                  method: 'new'
                })
              }}
              whiteSpace="nowrap"
            >
              New
            </Tab>
            <Tab
              onClick={() => {
                setSortState({
                  filterBy: defaultFilterBy,
                  graduated: false,
                  isDescending: true,
                  method: 'trending'
                })
              }}
              whiteSpace="nowrap"
            >
              Trending
            </Tab>
            <Tab
              onClick={() => {
                setSortState({
                  filterBy: 'd1',
                  graduated: true,
                  isDescending: true,
                  method: 'marketCap'
                })
              }}
              whiteSpace="nowrap"
            >
              Graduated
            </Tab>
          </TabList>
        </Tabs>

        <Tabs
          variant="bracketed"
          index={filterOptions.indexOf(sortState.filterBy)}
          onChange={(index) =>
            setSortState((prev) => ({
              ...prev,
              filterBy: filterOptions[index]
            }))
          }
          w="full"
          isFitted
        >
          <TabList>
            {filterOptions.map((option) => (
              <Tab
                key={option}
                whiteSpace="nowrap"
                isDisabled={sortState.graduated}
              >
                {option.toUpperCase()}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <HStack spacing={4} w="full">
          <ChainMenu currentChain={selectedChain} onChange={setSelectedChain} />

          {sortState.method === 'new' ? (
            <LiveIndicator
              isPaused={!isLive}
              onTogglePause={() => setIsPaused(!isPaused)}
              pausedDataCount={pausedMarketsCount}
            />
          ) : null}
        </HStack>

        <MarketsSortMenu
          currentSort={sortState.method}
          currentSortDirection={sortState.isDescending}
          graduated={sortState.graduated}
          onSort={(method, isDesc, graduated) => {
            setSortState({
              filterBy: graduated ? 'd1' : sortState.filterBy,
              graduated,
              isDescending: isDesc,
              method
            })
          }}
        />
      </Flex>

      {markets.map((market) => (
        <MarketCard key={market.marketAddress} market={market} />
      ))}

      {hasNextPage && !isLoading && (
        <Center mt={4} w="full">
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            color="textPrimary"
            isLoading={isFetchingNextPage}
            onClick={() => fetchNextPage()}
          >
            Load More
          </Button>
        </Center>
      )}
    </VStack>
  )
}

const TokenMill = () => {
  return (
    <Box maxW="1600px" margin="0 auto" pb={8}>
      <HomeHeader />

      <Hide below="md">
        <TokenMillDesktop />
      </Hide>

      <Show below="md">
        <TokenMillMobile />
      </Show>
    </Box>
  )
}

export default TokenMill
