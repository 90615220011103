import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React from 'react'
import { SolbarnMarketQueryParam } from 'types/solbarn'

const sortOptions: {
  isDesc: boolean
  label: string
  value: SolbarnMarketQueryParam.OrderBy
  graduated?: boolean
}[] = [
  { isDesc: true, label: 'Trending', value: 'trending' },
  { isDesc: true, label: 'New Tokens', value: 'new' },
  { graduated: true, isDesc: true, label: 'Graduated', value: 'marketCap' },
  { isDesc: true, label: 'Highest Market Cap', value: 'marketCap' },
  { isDesc: false, label: 'Lowest Market Cap', value: 'marketCap' },
  { isDesc: true, label: 'Highest Liquidity', value: 'liquidity' },
  { isDesc: false, label: 'Lowest Liquidity', value: 'liquidity' },
  { isDesc: true, label: 'Highest Volume', value: 'volume' },
  { isDesc: false, label: 'Lowest Volume', value: 'volume' }
]

const MarketsSortMenu = ({
  currentSort,
  currentSortDirection,
  graduated,
  onSort
}: {
  currentSort: SolbarnMarketQueryParam.OrderBy
  currentSortDirection: boolean
  graduated: boolean
  onSort: (
    method: SolbarnMarketQueryParam.OrderBy,
    isDesc: boolean,
    graduated: boolean
  ) => void
}) => {
  const selectedSortOption = sortOptions.find(
    (option) =>
      option.value === currentSort &&
      option.isDesc === currentSortDirection &&
      (option.graduated || false) === graduated
  )

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="boxShadowFlat"
        bg="bgSecondary"
        rightIcon={<ChevronDownIcon />}
        w={{ base: 'full', md: 'auto' }}
        textAlign="left"
      >
        {selectedSortOption?.label}
      </MenuButton>
      <MenuList>
        {sortOptions.map((option, index) => (
          <MenuItem
            key={index}
            fontSize="sm"
            fontWeight="semibold"
            h="40px"
            bg={
              selectedSortOption?.label === option.label
                ? '#3cb7fd'
                : 'transparent'
            }
            onClick={() => {
              onSort(option.value, option.isDesc, option.graduated || false)
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default MarketsSortMenu
