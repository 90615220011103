import { CheckCircleIcon } from '@chakra-ui/icons'
import { Badge, HStack, Text } from '@chakra-ui/react'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useGetMarketBadges from 'hooks/barn/useGetMarketBadges'
import React from 'react'
import { XCircleIcon } from 'theme/icons'
import { Chain } from 'types/dexbarn'

interface TokenBadgesProps {
  chain: Chain
  tokenAddress: string
}

const TokenBadge = ({
  isOn,
  label,
  tooltipLabel
}: {
  isOn: boolean
  label: string
  tooltipLabel?: string
}) => {
  if (isOn) {
    return (
      <TouchFriendlyTooltip label={tooltipLabel}>
        <Badge colorScheme="green" borderRadius={0}>
          <HStack>
            <CheckCircleIcon />
            <Text textColor="green.800" _dark={{ color: 'green.400' }}>
              {label}
            </Text>
          </HStack>
        </Badge>
      </TouchFriendlyTooltip>
    )
  }

  return (
    <TouchFriendlyTooltip label={tooltipLabel}>
      <Badge colorScheme="red" borderRadius={0}>
        <HStack>
          <XCircleIcon fill="red.800" _dark={{ fill: 'red.400' }} />
          <Text textColor="red.800" _dark={{ color: 'red.400' }}>
            {label}
          </Text>
        </HStack>
      </Badge>
    </TouchFriendlyTooltip>
  )
}

const TokenBadges = ({ chain, tokenAddress }: TokenBadgesProps) => {
  const { data: badges } = useGetMarketBadges({
    chain,
    tokenAddress
  })

  if (!badges) return null

  return (
    <HStack flexWrap="wrap">
      {badges.graduated ? (
        <TouchFriendlyTooltip label="Tokens are graduated when their market cap exceeds $50k">
          <Badge colorScheme="yellow" borderRadius={0}>
            <HStack>
              <CheckCircleIcon color="yellowBar" />
              <Text textColor="yellow.800" _dark={{ color: 'yellowBar' }}>
                Graduated
              </Text>
            </HStack>
          </Badge>
        </TouchFriendlyTooltip>
      ) : null}
      <TokenBadge
        isOn={badges.mintAuthorityDisabled}
        label="Mint Authority"
        tooltipLabel="It is not possible to mint new tokens with this token contract"
      />
      <TokenBadge
        isOn={badges.freeAuthorityDisabled}
        label="Freeze Authority"
        tooltipLabel="This token cannot be paused or frozen from transfer or transaction"
      />
      <TokenBadge isOn={badges.lpBurned} label="LP burned" />
      <TokenBadge
        isOn={badges.fairLaunch}
        label="Fair Launch"
        tooltipLabel="No vesting, no creator fees"
      />
      <TokenBadge
        isOn={badges.top10HoldersHoldLessThan15Pct}
        label="Top 10 holders < 15%"
        tooltipLabel="Top 10 holders own less than 15% of circulating supply"
      />
    </HStack>
  )
}

export default TokenBadges
