import { useSolbarnClient } from 'hooks/useDexbarn'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useReferralStorage } from 'state/referral'
import { isSolanaAddress } from 'utils/addresses'
import { isAddress } from 'viem'

const ReferralHandler: React.FC = () => {
  const solbarnClient = useSolbarnClient()
  const location = useLocation()
  const { referralState, updateReferralInfo } = useReferralStorage()
  const { evm, solana } = referralState

  useEffect(() => {
    const handleReferralCode = async (refcode: string) => {
      const referrerAddress = await solbarnClient
        .get<string>(`/v2/users/referral-codes/${refcode}`)
        .then((r) => r.data)
        .catch(() => null)

      if (referrerAddress) {
        if (isAddress(referrerAddress)) {
          updateReferralInfo('evm', refcode, referrerAddress)
        } else if (isSolanaAddress(referrerAddress)) {
          updateReferralInfo('solana', refcode, referrerAddress)
        }
      }
    }

    const searchParams = new URLSearchParams(location.search)
    const urlReferralCode = searchParams.get('r')

    const isAlreadyReferredOnEVM = !!evm.code
    const isAlreadyReferredOnSolana = !!solana.code

    // Only update if there's a code in the URL and no existing code stored
    if (
      urlReferralCode &&
      (!isAlreadyReferredOnEVM || !isAlreadyReferredOnSolana)
    ) {
      handleReferralCode(urlReferralCode)
    }
  }, [location, updateReferralInfo, solbarnClient, evm.code, solana.code])

  return null
}

export default ReferralHandler
