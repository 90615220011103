import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears
} from 'date-fns'
import Numeral from 'numeral'

const priceFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  minimumFractionDigits: 2,
  style: 'currency'
})

const toK = (num: string) => {
  return Numeral(num).format('0.[00]a')
}

export const formatNumberNoDecimalsToK = (
  num: number,
  options: { usd: boolean } = { usd: false }
) => {
  if (num === 0) {
    return options.usd ? '$0' : '0'
  }

  if (num < 1) {
    return options.usd ? '<$1' : '<1'
  }

  const formattedNum = Numeral(num.toFixed(0)).format('0[.][00]a')

  return options.usd ? `$${formattedNum}` : formattedNum
}

/**
 * This function shortens small decimal numbers into a shortened format:
 * E.g. 0.00000000123 becomes 0.0₈123.
 * Must only accept numbers between 0 < n < 1.
 */
const formatSmallDecimalNumber = (
  price: number | string,
  options: { places: number } = { places: 5 }
): string => {
  let priceNum, priceStr
  if (typeof price === 'number') {
    priceStr = price.toString()
    priceNum = price
  } else {
    priceStr = price
    priceNum = Number(price)
  }

  if (priceNum <= 0 || priceNum >= 1) return priceStr
  const exp = -Math.floor(Math.log(priceNum) / Math.log(10) + 1)

  // We only shorten for numbers which have 3 or more zeroes after the decimal point
  if (exp < 3) return priceStr

  // Using a function to handle the subscript
  const subscriptExp = getSubscriptExp(exp)

  const nonZeroPart = getNonZeroPart(priceStr)
  const limitedNonZeroPart = nonZeroPart.substring(0, options.places)

  return `0.0${subscriptExp}${limitedNonZeroPart}`
}

/**
 * This function accepts a small decimal number and returns a number string
 * with all the leading zeroes removed including the decimal point.
 * E.g. 0.00000123 => "123"
 */
const getNonZeroPart = (numStr: string): string => {
  const num = Number(numStr)
  if (num >= 1 || num <= 0) return ''

  let j
  for (let i = 0; i < numStr.length; i++) {
    if (numStr[i] != '0' && numStr[i] != '.') {
      j = i
      break
    }
  }
  return numStr.slice(j)
}

/**
 * This function accepts an exponent number and returns its subscript representation.
 * E.g. 10 => "₁₀"
 */
const getSubscriptExp = (exp: number): string => {
  const subscriptNumbers = ['₀', '₁', '₂', '₃', '₄', '₅', '₆', '₇', '₈', '₉']
  return exp
    .toString()
    .split('')
    .map((digit) => subscriptNumbers[parseInt(digit)])
    .join('')
}

interface FormattedNumOptions {
  allowDecimalsOver1000?: boolean
  allowSmallDecimals?: boolean
  places?: number
  usd?: boolean
}

export const formattedNum = (
  number: number | string,
  options?: FormattedNumOptions
) => {
  const {
    allowDecimalsOver1000 = false,
    allowSmallDecimals = false,
    places = 10,
    usd = false
  } = options || {}

  if (
    (typeof number === 'number' && isNaN(number)) ||
    number === '' ||
    number === undefined ||
    number === null
  ) {
    return usd ? '$0.00' : '0'
  }
  const num = typeof number === 'string' ? parseFloat(number) : number

  if (num > 500_000_000) {
    return (usd ? '$' : '') + toK(num.toFixed(0))
  }

  if (num < -500_000_000) {
    return `-${usd ? '$' : ''}${toK((num * -1).toFixed(0))}`
  }

  if (num === 0) {
    return usd ? '$0' : '0'
  }

  if (!allowSmallDecimals && num < 0.0001 && num > 0) {
    return usd ? '< $0.01' : places === 0 ? '< 0.01' : '< 0.0001'
  }

  if (num > 1000) {
    if (allowDecimalsOver1000) {
      return usd
        ? '$' + Number(parseFloat(String(num)).toFixed(places)).toLocaleString()
        : '' + Number(parseFloat(String(num)).toFixed(places)).toLocaleString()
    } else {
      return usd
        ? '$' + Number(parseFloat(String(num)).toFixed(0)).toLocaleString()
        : '' + Number(parseFloat(String(num)).toFixed(0)).toLocaleString()
    }
  }

  if (num < -1000) {
    const val = allowDecimalsOver1000
      ? Number(parseFloat(String(num * -1)).toFixed(places))
      : Number(parseFloat(String(num * -1)).toFixed(0)).toLocaleString()
    return `-${usd ? '$' : ''}${val}`
  }

  if (usd && num >= 1) {
    const usdString = priceFormatter.format(num)
    return '$' + usdString.slice(1, usdString.length)
  }

  // double parseFloat wrap needed to remove trailing 0s
  const formattedNumStr = parseFloat(
    parseFloat(String(num)).toFixed(places)
  ).toString()
  // convert scientific numbers to decimal
  const decimalNum = scientificToDecimal(formattedNumStr)

  if (num < 1 && num > 0) {
    return usd
      ? '$' + formatSmallDecimalNumber(decimalNum)
      : formatSmallDecimalNumber(decimalNum)
  }

  return formattedNumStr
}

// https://gist.github.com/jiggzson/b5f489af9ad931e3d186
const scientificToDecimal = (n: number | string) => {
  let num = Number(n)
  let numStr = num.toString()
  const nsign = Math.sign(num)
  //remove the sign
  num = Math.abs(num)
  //if the number is in scientific notation remove it
  if (/\d+\.?\d*e[\+\-]*\d+/i.test(numStr)) {
    const zero = '0',
      parts = numStr.toLowerCase().split('e'), //split into coeff and exponent
      e = parts.pop() //store the exponential part
    let l = Math.abs(Number(e)) //get the number of zeros
    const sign = Number(e) / l,
      coeff_array = parts[0].split('.')
    if (sign === -1) {
      l = l - coeff_array[0].length
      if (l < 0) {
        numStr =
          coeff_array[0].slice(0, l) +
          '.' +
          coeff_array[0].slice(l) +
          (coeff_array.length === 2 ? coeff_array[1] : '')
      } else {
        numStr = zero + '.' + new Array(l + 1).join(zero) + coeff_array.join('')
      }
    } else {
      const dec = coeff_array[1]
      if (dec) l = l - dec.length
      if (l < 0) {
        numStr = coeff_array[0] + dec.slice(0, l) + '.' + dec.slice(l)
      } else {
        numStr = coeff_array.join('') + new Array(l + 1).join(zero)
      }
    }
  }

  return nsign < 0 ? '-' + numStr : numStr
}

export function formatShortDistanceToNow(date: Date) {
  const now = new Date()
  const secondsDiff = differenceInSeconds(now, date)

  if (secondsDiff < 60) {
    return `${secondsDiff}s`
  }

  const minutesDiff = differenceInMinutes(now, date)
  if (minutesDiff < 60) {
    return `${minutesDiff}m`
  }

  const hoursDiff = differenceInHours(now, date)
  if (hoursDiff < 24) {
    return `${hoursDiff}h`
  }

  const daysDiff = differenceInDays(now, date)
  if (daysDiff < 30) {
    return `${daysDiff}d`
  }

  const monthsDiff = differenceInMonths(now, date)
  if (monthsDiff < 12) {
    return `${monthsDiff}mo`
  }

  const yearsDiff = differenceInYears(now, date)
  return `${yearsDiff}y`
}
